/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Col,
    Row
} from "reactstrap";

import routes from "routes.js";
import logo from "assets/img/LOGO_CORSO_HORIZONTAL.png";
import { ImPhone } from "react-icons/im";
import axios from 'utils/AxiosHelper';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseOpen: false,
            color: "bg-white",
            contactData: {}
        };
        this.activeRoute.bind(this);
        this.getContactData();
    }

	getContactData = () => {
		axios.get('auth/contact/contactCard')
		.then(response => {
			if(response.status === 200){
                this.setState({contactData: response.data});
			}
		})
	}

    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1
            ? true
            : false;
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateColor);
    }
    
    // this function opens and closes the collapse on small devices
    toggleCollapse = () => {
        this.setState({
            collapseOpen: !this.state.collapseOpen
        });
    };

    render() {
        return (
            <>
                <Container fluid style={{backgroundColor: 'white'}}>
                    <Row>
                        <Col xs="12" sm="6" md="4">
                            <Row className="align-items-center justify-content-center">
                                <p style={styles.pHeader}>
                                    <i className="tim-icons icon-email-85" style={{marginRight: 4}}/>
                                    {this.state.contactData.email}
                                </p>
                            </Row>
                        </Col>
                        <Col className="d-none d-md-flex align-items-center justify-content-center" xs="12" sm="6" md="4">
                            <p style={styles.pHeader}>
                                <ImPhone size={15} color="#707070" />
                                {this.state.contactData.phone_number}
                                </p>
                        </Col>
                        <Col xs="12" md="4">
                            <Row className="d-none d-md-flex align-items-center justify-content-center">
                                <p style={styles.pHeader}>
                                    <i className="tim-icons icon-square-pin" style={{marginRight: 4}}/>
                                    {this.state.contactData.address}
                                    </p>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Navbar
                    className={classNames("navbar", this.state.color)}
                    expand="lg"
                >
                    <Container fluid>
                        <div className="logo-img pl-sm">
                            <img style={{maxWidth: '120px'}} src={logo} alt="react-logo" />
                        </div>
                        <div className="navbar-wrapper">
                            <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                                {this.props.brandText}
                            </NavbarBrand>
                        </div>
                        <button
                            aria-expanded={false}
                            aria-label="Toggle navigation"
                            className="navbar-toggler"
                            data-target="#navigation"
                            data-toggle="collapse"
                            id="navigation"
                            type="button"
                            onClick={this.toggleCollapse}
                        >
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                        <Collapse navbar isOpen={this.state.collapseOpen}>
                            <Nav className="ml-auto" navbar>
                                {routes.map((prop, key) => {
                                    if (prop.redirect) return null;
                                    return (
										<li key={key} className="active">
                                            <NavLink
                                                // to={prop.path}
                                                style={{cursor: 'pointer'}}
                                                activeClassName="active"
                                                onClick={() => {
                                                    this.props.history.push(prop.path);
                                                }}
											>
												<p
													style={{
														color: "#426fb8",
														borderWidth: this.activeRoute(
															prop.path
														)
															? "5px 0px 0px 0px"
															: "0px",
														borderStyle: "solid",
														fontWeight: "600",
													}}
												>
													{" "}
													{prop.name}
												</p>
											</NavLink>
										</li>
									);
                                })}
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </>
        );
    }
}

const styles = {
    pHeader: {
        textAlign: 'center',
        color: '#707070',
        fontWeight: '500',
        paddingTop: 5
    }
}

export default Header;
