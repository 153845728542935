/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Row,
	Col,
	Button,
	FormGroup,
	Form,
	Input,
} from "reactstrap";
import Files from "react-files";
import swal from 'sweetalert';

import axios from 'utils/AxiosHelper';

var imgName = "";
class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bannerTitle: "",
			bannerDescription: "",
			formCarousel: {
				url: "",
				title: "",
				img: "",
			},
			formsCards: {
				tilecard1: "",
				msgcard1: "",
				tilecard2: "",
				msgcard2: "",
				tilecard3: "",
				msgcard3: ""
			},
			services: {
				tilecard1: "",
				msgcard1: "",
				tilecard2: "",
				msgcard2: ""
			},
			carouselData: [],
		};

		this.getAllData();
	}

	getAllData = async() => {
		var infoBanner = {};
		var auxFormsCards = {
			tilecard1: "",
			msgcard1: "",
			tilecard2: "",
			msgcard2: "",
			tilecard3: "",
			msgcard3: "",
		};
		var auxServicesCards = {
			tilecard1: "",
			msgcard1: "",
			tilecard2: "",
			msgcard2: ""
		};
		await axios.get("auth/home/informationBanner")
			.then(response => {
				if (response.status === 200) {
					infoBanner.title = response.data.title;
					infoBanner.description = response.data.description;
				}
			});
		await axios.get("auth/home/summaryCards")
			.then(response => {
				if (response.status === 200) {
					auxFormsCards.tilecard1 = response.data.data[0].title;
					auxFormsCards.msgcard1 = response.data.data[0].description;
					auxFormsCards.tilecard2 = response.data.data[1].title;
					auxFormsCards.msgcard2 = response.data.data[1].description;
					auxFormsCards.tilecard3 = response.data.data[2].title;
					auxFormsCards.msgcard3 = response.data.data[2].description;
				}
			});
		await axios.get("auth/home/services")
			.then(response => {
				if (response.status === 200) {
					auxServicesCards.tilecard1 = response.data.data[0].title;
					auxServicesCards.msgcard1 = response.data.data[0].description;
					auxServicesCards.tilecard2 = response.data.data[1].title;
					auxServicesCards.msgcard2 = response.data.data[1].description;
				}
			});
		this.setState(prevState => {
			return {
				...prevState,
				bannerTitle: infoBanner.title,
				bannerDescription: infoBanner.description,
				formsCards: auxFormsCards,
				services: auxServicesCards
			}
		});
	}

	/**
	 * Función para guardar un nuevo item del carrusel
	 * @param {*} e evento
	 */
	onSaveClickHandler = () => {
		axios.post("auth/home/slider", {
			title: this.state.formCarousel.title,
			image: this.state.formCarousel.img
		})
		.then(response => {
			if(response.status === 200){
				swal("Correcto", "Agregado al carrusel correctamente", "success");
			}
		})
	};

	onAddVideo = () => {
		axios.post("auth/home/slider", {url: this.state.formCarousel.url})
		.then(response => {
			if(response.status === 200){
				swal("Correcto", "Agregado al carrusel correctamente", "success");
			}
		})
	};

	/**
	 * Función para cambiar los datos del formulario
	 * @param {*} e evento
	 */
	onChangeCarouselFormData = (e) => {
		this.setState({
			formCarousel: {
				...this.state.formCarousel,
				[e.target.name]:
					e.target.name === "img" ? e.Files[0] : e.target.value,
			},
		});
	};

	/**
	 * Función para cambiar los datos del formulario
	 * @param {*} e evento
	 */
	onChangeCardsFormData = (e) => {
		this.setState({
			formsCards: {
				...this.state.formsCards,
				[e.target.name]:
					e.target.name === "img" ? e.Files[0] : e.target.value,
			},
		});
	};
	
	onChangeCardsServices = (e) => {
		this.setState({
			services: {
				...this.state.services,
				[e.target.name]: e.target.value,
			},
		});
	};

	onSaveServices = (num) => {
		var data = {};
		data.title = this.state.services[`tilecard${num}`];
		data.description = this.state.services[`msgcard${num}`];
		data._method = "put";
		axios.post(`auth/home/services/${num}`, data)
		.then(response => {
			if(response.status === 200){
				swal("Correcto", "Tarjeta actializada correctamente", "success");
			}
		});
	}

	/**
	 * Función para guardar las tarjetas
	 * @param {*} e evento
	 */
	onSaveCards = (num) => {
		var data = {};
		data.title = this.state.formsCards[`tilecard${num}`];
		data.description = this.state.formsCards[`msgcard${num}`];
		data._method = "put";
		axios.post(`auth/home/summaryCards/${num}`, data)
		.then(response => {
			if(response.status === 200){
				swal("Correcto", "Tarjeta actializada correctamente", "success");
			}
		});
	};

	onSaveBanner = () => {
		axios.post("auth/home/informationBanner/1", {
			title: this.state.bannerTitle,
			description: this.state.bannerDescription,
			_method: "put"
		})
			.then(response => {
				if(response.status === 200){
					swal("Correcto","Banner actualizado con exito", "success");
				}
			});
	}

	render() {
		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							{/* Tarjeta para Banner de información de inicio */}
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Banner de Información
									</CardTitle>
								</CardHeader>
								<CardBody>
									<Form>
										<FormGroup>
											<label>Titulo</label>
											<Input name="bannerTitle" value={this.state.bannerTitle}
												onChange={(e) => {
													this.setState({[e.target.name]: e.target.value});
												}}
												placeholder="Titulo"
												type="text"
											/>
										</FormGroup>
										<FormGroup>
											<label>Descripción</label>
											<Input
												name="bannerDescription"
												value={this.state.bannerDescription}
												onChange={(e) => {
													this.setState({[e.target.name]: e.target.value});
												}}
												placeholder="..."
												cols="80"
												rows="4"
												type="textarea"
											/>
										</FormGroup>
									</Form>
								</CardBody>
								<CardFooter>
									<Button
										onClick={this.onSaveBanner}
										className="btn-fill"
										color="primary"
									>
										Actualizar
									</Button>
								</CardFooter>
							</Card>
							{/* Tarjeta para Servicios de inicio */}
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Servicios
									</CardTitle>
								</CardHeader>
								<CardBody>
									<CardTitle tag="h4">Primer tarjeta</CardTitle>
									<FormGroup>
										<label>Titulo</label>
										<Input name="tilecard1" value={this.state.services.tilecard1}
											onChange={(e) => { this.onChangeCardsServices(e); }}
											placeholder="Titulo"
											type="text"
										/>
									</FormGroup>
									<FormGroup>
										<label>Descripción</label>
										<Input
											name="msgcard1"
											value={this.state.services.msgcard1}
											onChange={(e) => { this.onChangeCardsServices(e); }}
											placeholder="..."
											cols="80"
											rows="4"
											type="textarea"
										/>
									</FormGroup>
									<Button onClick={() => this.onSaveServices(1)} className="btn-fill" color="primary" >
										Actualizar
									</Button>
									
									<CardTitle tag="h4">Segunda tarjeta</CardTitle>
									<FormGroup>
										<label>Titulo</label>
										<Input name="tilecard2" value={this.state.services.tilecard2}
											onChange={(e) => { this.onChangeCardsServices(e); }}
											placeholder="Titulo"
											type="text"
										/>
									</FormGroup>
									<FormGroup>
										<label>Descripción</label>
										<Input
											name="msgcard2"
											value={this.state.services.msgcard2}
											onChange={(e) => { this.onChangeCardsServices(e); }}
											placeholder="..."
											cols="80"
											rows="4"
											type="textarea"
										/>
									</FormGroup>
									<Button onClick={() => this.onSaveServices(2)} className="btn-fill" color="primary" >
										Actualizar
									</Button>
								</CardBody>
							</Card>
							{/* Tarjeta para editar las tarjetas del Menu de inicio */}
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Tarjetas de inicio
									</CardTitle>
								</CardHeader>
								<CardBody>
									<Form>
										<Col>
											<CardTitle tag="h4">Primer tarjeta</CardTitle>
											<FormGroup>
												<label>Titulo</label>
												<Input
													name="tilecard1"
													value={this.state.formsCards.tilecard1}
													onChange={(e) => {
														this.onChangeCardsFormData(e);
													}}
													placeholder="Titulo"
													type="text"
												/>
											</FormGroup>
											<FormGroup>
												<label>Mensaje</label>
												<Input
													name="msgcard1"
													value={this.state.formsCards.msgcard1}
													onChange={(e) => {
														this.onChangeCardsFormData(e);
													}}
													placeholder="..."
													cols="80"
													rows="4"
													type="textarea"
												/>
											</FormGroup>
											<Button onClick={() => this.onSaveCards(1)} className="btn-fill" color="primary" >
												Actualizar
											</Button>
										</Col>
										<Col>
											<CardTitle tag="h4">Segunda tarjeta</CardTitle>
											<FormGroup>
												<label>Titulo</label>
												<Input
													name="tilecard2"
													value={
														this.state.formsCards.tilecard2
													}
													onChange={(e) => {
														this.onChangeCardsFormData(
															e
														);
													}}
													placeholder="Titulo"
													type="text"
												/>
											</FormGroup>
											<FormGroup>
												<label>Mensaje</label>
												<Input
													name="msgcard2"
													value={this.state.formsCards.msgcard2}
													onChange={(e) => {
														this.onChangeCardsFormData(e);
													}}
													placeholder="..."
													cols="80"
													rows="4"
													type="textarea"
												/>
											</FormGroup>
											<Button onClick={() => this.onSaveCards(2)} className="btn-fill" color="primary" >
												Actualizar
											</Button>
										</Col>
										<Col>
											<CardTitle tag="h4">Tercer tarjeta</CardTitle>
											<FormGroup>
												<label>Titulo</label>
												<Input
													name="tilecard3"
													value={this.state.formsCards.tilecard3}
													onChange={(e) => {
														this.onChangeCardsFormData(e);
													}}
													placeholder="Titulo"
													type="text"
												/>
											</FormGroup>
											<FormGroup>
												<label>Mensaje</label>
												<Input
													name="msgcard3"
													value={this.state.formsCards.msgcard3}
													onChange={(e) => {
														this.onChangeCardsFormData(e);
													}}
													placeholder="..."
													cols="80"
													rows="4"
													type="textarea"
												/>
											</FormGroup>
										</Col>
									</Form>
								</CardBody>
								<CardFooter>
									<Button onClick={() => this.onSaveCards(3)} className="btn-fill" color="primary" >
										Actualizar
									</Button>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default Home;
