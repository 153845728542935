/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Row,
	Col,
	Button,
	FormGroup,
	Form,
	Input,
} from "reactstrap";
import Files from "react-files";
import swal from 'sweetalert';

import axios from 'utils/AxiosHelper';

var imgName = "";
class General extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            aboutUsImage: {preview: {url: ''}},
            aboutUsDescription: '',
            productsImage: {preview: {url: ''}},
            productsDescription: '',
            contactImage: {preview: {url: ''}},
            contactDescription: '',
            innovationImage: {preview: {url: ''}},
            innovationDescription: '',
            cotizaImage: {preview: {url: ''}},
            cotizaDescription: '',
            longitude: '',
            latitude: '',
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
			whatsapp: '',
			address: '',
			phone_number: '',
			email: ''
        };

		this.getAllData();
	}

	getAllData = async() => {
        await axios.get(`auth/general/banner`)
        .then(response => {
            var data = response.data.data;
            this.setState({
                aboutUsDescription: data[0].title,
                aboutUsImage: {preview: {url: `${axios.baseURLIMG}${data[0].image}`}},
                productsDescription: data[1].title,
                productsImage: {preview: {url: `${axios.baseURLIMG}${data[1].image}`}},
                contactDescription: data[2].title,
                contactImage: {preview: {url: `${axios.baseURLIMG}${data[2].image}`}},
                innovationDescription: data[3].title,
                innovationImage: {preview: {url: `${axios.baseURLIMG}${data[3].image}`}},
                cotizaDescription: data[4].title,
                cotizaImage: {preview: {url: `${axios.baseURLIMG}${data[4].image}`}},
            });
        })
        await axios.get("auth/general/socialMedia")
        .then(response => {
            var data = response.data;
            this.setState({
                longitude: data.longitude,
                latitude: data.latitude,
                facebook: data.facebook,
                twitter: data.twitter,
                instagram: data.instagram,
                youtube: data.youtube,
                whatsapp: data.whatsapp
            })
        })
		await axios.get('auth/contact/contactCard')
		.then(response => {
			if (response.status === 200) {
				this.setState({
					...this.state,
					address: response.data.address,
					phone_number: response.data.phone_number,
					email: response.data.email,
				})
			}
		});
    }
    
    onUpdateBanner = (num) => {
        var data = {};
        switch (num) {
            case 1:
                data.title = this.state.aboutUsDescription;
                data.image = this.state.aboutUsImage;
                break;
            case 2:
                data.title = this.state.productsDescription;
                data.image = this.state.productsImage;
                break;
            case 3:
                data.title = this.state.contactDescription;
                data.image = this.state.contactImage;
                break;
            case 4:
                data.title = this.state.innovationDescription;
                data.image = this.state.innovationImage;
                break;
            case 5:
                data.title = this.state.cotizaDescription;
                data.image = this.state.cotizaImage;
                break;
            default:
                break;
        }
        data._method = "put";
        axios.post(`auth/general/banner/${num}`, data)
		.then(response => {
			if (response.status === 200) {
                swal("Correcto", "Banner actualizado correctamente", "success");
				this.getAllData();
			}
        })
    }

    onSaveSocial = () => {
        axios.post(`auth/general/socialMedia/1`, {
            longitude: this.state.longitude,
            latitude: this.state.latitude,
            facebook: this.state.facebook,
            twitter: this.state.twitter,
            instagram: this.state.instagram,
            youtube: this.state.youtube,
            whatsapp: this.state.whatsapp,
            _method: 'put'
        })
        .then(response => {
            if(response.status === 200){
                swal("Correcto", "Datos actualizados correctamente", "success");
            }
        })
    }

	onSaveContactCard = () => {
		axios.post(`auth/contact/contactCard/1`, {
			address: this.state.address,
			phone_number: this.state.phone_number,
			email: this.state.email,
			_method: "put"
		})
		.then(response => {
			if (response.status === 200) {
                swal("Correcto", "Tarjeta de contacto actualizada correctamente", "success");
				this.getAllData();
			}
        })
	}

	render() {
		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							{/* Tarjeta para agregar items al carrusel de inicio */}
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3"> Banners </CardTitle>
								</CardHeader>
								<CardBody>
                                    {/* Banner Nosotros */}
                                    <CardTitle tag="h3"> Nosotros </CardTitle>
									<FormGroup>
										<label>Imagen</label>
										<Files
											className="files-dropzone"
											onChange={(file) => { this.setState({ aboutUsImage: file[0] }); }}
											accepts={["image/*"]} multiple={false} maxFileSize={10000000} minFileSize={0}
										>
											<p style={{ borderStyle: "dashed", color: "white", borderWidth: 0.5, padding: 15 }} className={"dashed"} >
												Arrastre imagenes aquí o presione para agregar
											</p>
										</Files>
									</FormGroup>
									<label>Previsualización</label>
									<FormGroup>
										<img alt="" src={this.state.aboutUsImage.preview.url} style={{ maxHeight: 200 }} />
									</FormGroup>
									<FormGroup>
										<label>Descripción</label>
										<Input
											name="title" value={this.state.aboutUsDescription}
											onChange={(e) => { this.setState({aboutUsDescription: e.target.value}) }}
											placeholder="..." cols="80" rows="4" type="textfield"
										/>
									</FormGroup>
									<Button onClick={() => {this.onUpdateBanner(1)}} className="btn-fill" color="primary" > Agregar </Button>
                                    {/* Banner Productos */}
                                    <CardTitle tag="h3" style={{marginTop: 15}}> Productos </CardTitle>
									<FormGroup>
										<label>Imagen</label>
										<Files
											className="files-dropzone"
											onChange={(file) => { this.setState({ productsImage: file[0] }); }}
											accepts={["image/*"]} multiple={false} maxFileSize={10000000} minFileSize={0}
										>
											<p style={{ borderStyle: "dashed", color: "white", borderWidth: 0.5, padding: 15 }} className={"dashed"} >
												Arrastre imagenes aquí o presione para agregar
											</p>
										</Files>
									</FormGroup>
									<label>Previsualización</label>
									<FormGroup>
										<img alt="" src={this.state.productsImage.preview.url} style={{ maxHeight: 200 }} />
									</FormGroup>
									<FormGroup>
										<label>Descripción</label>
										<Input
											name="title" value={this.state.productsDescription}
											onChange={(e) => { this.setState({productsDescription: e.target.value}) }}
											placeholder="..." cols="80" rows="4" type="textfield"
										/>
									</FormGroup>
									<Button onClick={() => {this.onUpdateBanner(2)}} className="btn-fill" color="primary" > Agregar </Button>
                                    {/* Banner Contacto */}
                                    <CardTitle tag="h3" style={{marginTop: 15}}> Contacto </CardTitle>
									<FormGroup>
										<label>Imagen</label>
										<Files
											className="files-dropzone"
											onChange={(file) => { this.setState({ contactImage: file[0] }); }}
											accepts={["image/*"]} multiple={false} maxFileSize={10000000} minFileSize={0}
										>
											<p style={{ borderStyle: "dashed", color: "white", borderWidth: 0.5, padding: 15 }} className={"dashed"} >
												Arrastre imagenes aquí o presione para agregar
											</p>
										</Files>
									</FormGroup>
									<label>Previsualización</label>
									<FormGroup>
										<img alt="" src={this.state.contactImage.preview.url} style={{ maxHeight: 200 }} />
									</FormGroup>
									<FormGroup>
										<label>Descripción</label>
										<Input
											name="title" value={this.state.contactDescription}
											onChange={(e) => { this.setState({contactDescription: e.target.value}) }}
											placeholder="..." cols="80" rows="4" type="textfield"
										/>
									</FormGroup>
									<Button onClick={() => {this.onUpdateBanner(3)}} className="btn-fill" color="primary" > Agregar </Button>
                                    {/* Banner Innovación */}
                                    <CardTitle tag="h3" style={{marginTop: 15}}> Innovación </CardTitle>
									<FormGroup>
										<label>Imagen</label>
										<Files
											className="files-dropzone"
											onChange={(file) => { this.setState({ innovationImage: file[0] }); }}
											accepts={["image/*"]} multiple={false} maxFileSize={10000000} minFileSize={0}
										>
											<p style={{ borderStyle: "dashed", color: "white", borderWidth: 0.5, padding: 15 }} className={"dashed"} >
												Arrastre imagenes aquí o presione para agregar
											</p>
										</Files>
									</FormGroup>
									<label>Previsualización</label>
									<FormGroup>
										<img alt="" src={this.state.innovationImage.preview.url} style={{ maxHeight: 200 }} />
									</FormGroup>
									<FormGroup>
										<label>Descripción</label>
										<Input
											name="title" value={this.state.innovationDescription}
											onChange={(e) => { this.setState({innovationDescription: e.target.value}) }}
											placeholder="..." cols="80" rows="4" type="textfield"
										/>
									</FormGroup>
									<Button onClick={() => {this.onUpdateBanner(4)}} className="btn-fill" color="primary" > Agregar </Button>
                                    {/* Banner Cotiza */}
                                    <CardTitle tag="h3" style={{marginTop: 15}}> Cotiza </CardTitle>
									<FormGroup>
										<label>Imagen</label>
										<Files
											className="files-dropzone"
											onChange={(file) => { this.setState({ cotizaImage: file[0] }); }}
											accepts={["image/*"]} multiple={false} maxFileSize={10000000} minFileSize={0}
										>
											<p style={{ borderStyle: "dashed", color: "white", borderWidth: 0.5, padding: 15 }} className={"dashed"} >
												Arrastre imagenes aquí o presione para agregar
											</p>
										</Files>
									</FormGroup>
									<label>Previsualización</label>
									<FormGroup>
										<img alt="" src={this.state.cotizaImage.url} style={{ maxHeight: 200 }} />
									</FormGroup>
									<FormGroup>
										<label>Descripción</label>
										<Input
											name="title" value={this.state.cotizaDescription}
											onChange={(e) => { this.setState({cotizaDescription: e.target.value}) }}
											placeholder="..." cols="80" rows="4" type="textfield"
										/>
									</FormGroup>
									<Button onClick={() => {this.onUpdateBanner(5)}} className="btn-fill" color="primary" > Agregar </Button>
								</CardBody>
							</Card>
                            {/* Tarjeta Redes Sociales */}
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Redes Sociales
									</CardTitle>
								</CardHeader>
								<CardBody>
									<Form>
										<FormGroup>
											<label>Latitud</label>
											<Input name="latitude" value={this.state.latitude}
												onChange={(e) => {
													this.setState({[e.target.name]: e.target.value});
												}}
												placeholder="Latitud"
												type="text"
											/>
										</FormGroup>
										<FormGroup>
											<label>Longitud</label>
											<Input name="longitude" value={this.state.longitude}
												onChange={(e) => {
													this.setState({[e.target.name]: e.target.value});
												}}
												placeholder="Longitud"
												type="text"
											/>
										</FormGroup>
										<FormGroup>
											<label>Facebook</label>
											<Input name="facebook" value={this.state.facebook}
												onChange={(e) => {
													this.setState({[e.target.name]: e.target.value});
												}}
												placeholder="Facebook"
												type="text"
											/>
										</FormGroup>
										<FormGroup>
											<label>Twitter</label>
											<Input name="twitter" value={this.state.twitter}
												onChange={(e) => {
													this.setState({[e.target.name]: e.target.value});
												}}
												placeholder="Twitter"
												type="text"
											/>
										</FormGroup>
										<FormGroup>
											<label>Instagram</label>
											<Input name="instagram" value={this.state.instagram}
												onChange={(e) => {
													this.setState({[e.target.name]: e.target.value});
												}}
												placeholder="Instagram"
												type="text"
											/>
										</FormGroup>
										<FormGroup>
											<label>YouTube</label>
											<Input name="youtube" value={this.state.youtube}
												onChange={(e) => {
													this.setState({[e.target.name]: e.target.value});
												}}
												placeholder="YouTube"
												type="text"
											/>
										</FormGroup>
										<FormGroup>
											<label>Whatsapp</label>
											<Input name="whatsapp" value={this.state.whatsapp}
												onChange={(e) => {
													this.setState({[e.target.name]: e.target.value});
												}}
												placeholder="Whatsapp"
												type="text"
											/>
										</FormGroup>
									</Form>
								</CardBody>
								<CardFooter>
									<Button onClick={this.onSaveSocial} className="btn-fill" color="primary" >
										Actualizar
									</Button>
								</CardFooter>
							</Card>
                            {/* Tarjeta Contacto */}
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Tarjeta de contacto
									</CardTitle>
								</CardHeader>
								<CardBody>
									<Form>
										<FormGroup>
											<label>Dirección</label>
											<Input name="address" value={this.state.address}
												onChange={(e) => {
													this.setState({[e.target.name]: e.target.value});
												}}
												placeholder="Dirección"
												type="text"
											/>
										</FormGroup>
										<FormGroup>
											<label>Número de télefono</label>
											<Input name="phone_number" value={this.state.phone_number}
												onChange={(e) => {
													this.setState({[e.target.name]: e.target.value});
												}}
												placeholder="3333102520"
												type="text"
											/>
										</FormGroup>
										<FormGroup>
											<label>Email</label>
											<Input name="email" value={this.state.email}
												onChange={(e) => {
													this.setState({[e.target.name]: e.target.value});
												}}
												placeholder="email@email.com"
												type="text"
											/>
										</FormGroup>
									</Form>
								</CardBody>
								<CardFooter>
									<Button onClick={this.onSaveContactCard} className="btn-fill" color="primary" >
										Actualizar
									</Button>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default General;
