import React, { useState, useEffect } from 'react';

import {
	Row,
    Container,
	Col
} from "reactstrap";
import {
  useParams,
} from "react-router-dom";
import {
    Typography,
    Grid
}
from '@material-ui/core';

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import axios from 'utils/AxiosHelper';

const Producto = (props) => {
    let { producId } = useParams();

    const [product, setProduct] = useState({});
    const [loadedData, setLoadedData] = useState(false);

    useEffect(() => {
        getProduct();
    }, []);

    const getProduct = () => {
        axios.get(`auth/product/${producId}`)
        .then(response => {
            if(response.status === 200){
                setProduct(response.data);
                setLoadedData(true);
            }
        })
    }

    return (
        <>
            <Header {...props} />
            {loadedData && <Container
                fluid
                className="d-flex align-items-center flex-column"
                style={{ padding: 0, margin: 0 , minHeight: '80vh'}}
            >
                <Grid container direction="row" justify="center" alignItems="center" style={{backgroundColor: '#426fb8'}}>
                    <Grid item xs={12} alignContent="center" >
                        <Typography 
                            variant="h2" component="h2" 
                            style={{color: 'white', textAlign: 'center'}}
                        > 
                            {product.category.name} 
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" style={{minHeight: '40vh'}}>
                    <Grid item xs={6} alignContent="center" container direction="column" justify="center" alignItems="flex-start" 
                        style={{padding: '10px 20px'}}
                    >
                        <Grid item xs={12}  alignContent="flex-start" >
                            <Typography 
                                variant="h4"
                                style={{color: 'black', textAlign: 'left'}}
                            > 
                                {product.title} 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} alignContent="left" >
                            <Typography 
                                variant="h4"
                                style={{color: '#426fb8', textAlign: 'left'}}
                            > 
                                Descripción
                            </Typography>
                        </Grid>
                        <Grid item xs={11} alignContent="left" >
                            <Typography 
                                variant="body1"
                                style={{color: 'black', textAlign: 'left'}}
                            > 
                                {product.description} 
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} alignContent="center" >
                        <img
                            style={{ maxHeight: "200px" }}
                            src={`${axios.baseURLIMG}${product.image}`}
                            alt={product.image_name}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" >
                    <Typography 
                        variant="caption"
                        style={{color: '#426fb8', textAlign: 'center'}}
                    > 
                        INGREDIENTES
                    </Typography>
                </Grid>
                <Grid container item xs={10} direction="row" justify="center" alignItems="center" >
                    <Typography 
                        style={{color: 'black', textAlign: 'center'}}
                    > 
                        {product.ingredients} 
                    </Typography>
                </Grid>
                {/* <Grid container direction="row" justify="center" alignItems="center" style={{backgroundColor: '#426fb8'}}>
                    <Typography 
                        variant="h3"
                        style={{color: 'white', textAlign: 'center'}}
                    > 
                        MÁS PRODUCTOS
                    </Typography>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" >
                </Grid> */}
            </Container>}
            <Footer {...props} />
        </>
    );
};

export default Producto;