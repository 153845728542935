/**
 * @fileoverview AxiosHelper
 * Axios Helper
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 2/11/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * 
 */

const axios = require('axios');

// const baseURL = 'http://127.0.0.1:8000/api/';
// const baseURLIMG = 'http://127.0.0.1:8000';
const baseURL = 'https://api.corsosupplies.com/api/';
const baseURLIMG = 'https://api.corsosupplies.com';

const post = (url, data, method = false) => {
    if (method) {
        data._method = method;
    }
    var form_data = new FormData();

    for (var key in data) {
        form_data.append(key, data[key]);
    }
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseURL}${url}`,
            form_data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
            .then(async response => {
                resolve(await response);
            })
            .catch(error => {
                reject(error);
            })
    })
}

const get = (url) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseURL}${url}`,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
            .then(async response => {
                resolve(await response);
            })
            .catch(error => {
                reject(error);
            })
    })
}

const del = (url) => {
    return new Promise((resolve, reject) => {
        axios.delete(
            `${baseURL}${url}`,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
            .then(async response => {
                resolve(await response);
            })
            .catch(error => {
                reject(error);
            })
    })
}

export default ({ post, get, del, baseURL, baseURLIMG });