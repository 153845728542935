/**
 * @fileoverview ConfigureStore
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 30/10/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * 
 */

// NPM Packages Imports
import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';

// Local Imports
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore() {
  const store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(thunkMiddleware, logger),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    ),
  );
  let persistor = persistStore(store);
  return {store, persistor};
}
