/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "adminComponents/Dashboard.js";
import UserProfile from "adminComponents/UserProfile.js";

// admin components
import Home from "adminComponents/Home/Home";
import Nosotros from "adminComponents/Nosotros/Nosotros";
import Innovacion from "adminComponents/Innovacion/Innovacion";
import General from 'adminComponents/General/General';
import Icons from 'adminComponents/Icons';
import Categories from 'adminComponents/Categories/Categories';
import Products from 'adminComponents/Products/Products';
import Customers from 'adminComponents/Customers/Customers';
import Carousel from 'adminComponents/Carousel/Carousel';

var routes = [
	{
		path: "/carrusel",
		name: "Carrusel",
		icon: "tim-icons icon-align-center",
		component: Carousel,
		layout: "/admin",
	},
	{
		path: "/inicio",
		name: "inicio",
		icon: "tim-icons icon-align-center",
		component: Home,
		layout: "/admin",
	},
	{
		path: "/clientes",
		name: "Clientes",
		icon: "tim-icons icon-single-02",
		component: Customers,
		layout: "/admin",
	},
	{
		path: "/nosotros",
		name: "nosotros",
		icon: "tim-icons icon-single-02",
		component: Nosotros,
		layout: "/admin",
	},
	{
		path: "/innovación",
		name: "innovación",
		icon: "tim-icons icon-bulb-63",
		component: Innovacion,
		layout: "/admin",
	},
	{
		path: "/login",
		name: "login",
		icon: "tim-icons icon-bulb-63",
		component: Icons,
		layout: "/admin",
		redirect: true
	},
	{
		path: "/general",
		name: "General",
		icon: "tim-icons icon-app",
		component: General,
		layout: "/admin",
	},
	{
		path: "/categories",
		name: "Categorias",
		icon: "tim-icons icon-cart",
		component: Categories,
		layout: "/admin",
	},
	{
		path: "/products",
		name: "Productos",
		icon: "tim-icons icon-basket-simple",
		component: Products,
		layout: "/admin",
	}
];
export default routes;
