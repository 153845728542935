import React, {useState, useEffect} from 'react';
// reactstrap components
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Row,
	Col,
	Button,
	FormGroup,
	Form,
	Input,
} from "reactstrap";
import Files from "react-files";
import swal from 'sweetalert';

import axios from 'utils/AxiosHelper';

const CategorieForm = (props) => {

    //state
    const [name, setName] = useState('');
    const [image, setImage] = useState({preview: {url: ''}});
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        if(props.category.hasOwnProperty('image')){
            setImage({preview: {url: `${axios.baseURLIMG}${props.category.image}`}});
            setName(props.category.name)
            setUpdate(true);
        }
    }, [])

    const onSaveClickHandler = () => {
        if( (name !== '') && (image !== {}) ){
            if(update){
                axios.post(`auth/product/productCategory/${props.category.id}`, {name, image}, 'put')
                .then(response => {
                    if(response.status === 200){
                        swal("Correcto", "Categoria agregada correctamente", "success");
                        props.renderCategories();
                    }
                })
                .catch(error => {
                    swal("Error", "Ocurrio un error al agregar la categoria", "error");
                })
            }
            else {
                axios.post(`auth/product/productCategory`, {name, image})
                .then(response => {
                    if(response.status === 200){
                        swal("Correcto", "Categoria agregada correctamente", "success");
                        props.renderCategories();
                    }
                })
                .catch(error => {
                    swal("Error", "Ocurrio un error al agregar la categoria", "error");
                })
            }
        }
    }
    
    return(
        <>
        <div  style={{display: 'flex', flexDirection: 'column'}}>
            <FormGroup>
                <label>Imagen</label>
                <Files
                    className="files-dropzone"
                        onChange={(file) => {
                        setImage(file[0]);
                    }}
                    onError={(err) => { } }
                    accepts={["image/*"]} multiple={false} maxFileSize={10000000} minFileSize={0}
                >
                    <p
                        style={{ borderStyle: "dashed", color: "black", borderWidth: 0.5, padding: 15 }}
                        className={"dashed"}
                    >
                        Arrastre imagenes aquí o presione para agregar
                    </p>
                </Files>
            </FormGroup>
            <label>previsualización</label>
            <FormGroup>
                <img alt="" src={image.preview.url} style={{ maxHeight: 200 }} />
            </FormGroup>
            <FormGroup>
                <label>Titulo</label>
                <Input
                    style={{color: 'black'}}
                    name="title"
                    value={name}
                    onChange={(e) => {setName(e.target.value)}}
                    placeholder="..."
                />
            </FormGroup>
            <Button onClick={onSaveClickHandler} className="btn-fill" color="primary" >
            { update? 'Actualizar':'Agregar'}
            </Button>
        </div>
        </>
    )
}

export default CategorieForm;