import React, { useState, useEffect } from 'react';
import {
    Col,
    Row,
    Button
} from 'reactstrap';
import axios from 'utils/AxiosHelper';

const product1s = [
    {
        title: "Galleta tipo sandwich",
        img: require("assets/img/productos/galletaSandwich.png")
    },
    {
        title: "Galleta Chocolate y vainilla",
        img: require("assets/img/productos/galletaChocolateVainilla.png")
    },
    {
        title: "Galleta tipo bownie",
        img: require("assets/img/productos/galletaBrownie.png")
    },
    {
        title: "Galleta tipo sandwich",
        img: require("assets/img/productos/galletaSandwich.png")
    },
    {
        title: "Galleta Chocolate y vainilla",
        img: require("assets/img/productos/galletaChocolateVainilla.png")
    },
    {
        title: "Galleta tipo bownie",
        img: require("assets/img/productos/galletaBrownie.png")
    },
]

const ProductList = (props) => {
    const [currectProduct, setCurrentProduct] = useState({});
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = () => {
        axios.get(`auth/product/category/${props.category.id}`)
        .then(response => {
            if(response.status === 200){
                setProducts(response.data);
            }
        })
    }

    const onProductPress = () => {
    }
    
    return (
        <>
            <h1 className="corsoTitle" style={{ margin: "15px 0px", color: "white" }} > {props.category.name} </h1>
            <Row className="justify-content-around align-items-center" style={{width: '90vw'}} >
                {products.map((product, key) => {
                    return (
                        <Col xs={6} md={4} className="d-flex flex-column justify-content-center align-items-center" >
                            <img
                                style={{ maxHeight: "200px" }}
                                src={`${axios.baseURLIMG}${product.image}`}
                                alt={product.title}
                            />
                            <p style={{ margin: "10px 0px" }}> {product.title} </p>
                            <Button
                                outline
                                color="secondary"
                                style={{
                                    alignSelf: "center",
                                }}
                                onClick={() => {props.history.push(`productos/${product.id}`)}}
                            >
                                VER MÁS
                            </Button>
                        </Col>
                    )
                })}
            </Row>
        </>
    );
}

export default ProductList;