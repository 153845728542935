import React, { useState, useEffect } from 'react'
import {
	FormGroup,
	Form,
	Input,
	Row,
    Container,
	Col,
	Button
} from "reactstrap";

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import { FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import { IoMdMail } from "react-icons/io";

import MapComponent from 'components/MapComponent/MapComponent';
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import axios from 'utils/AxiosHelper';
import swal from 'sweetalert';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const Contacto = (props) => {

	//state
	const [name, setName] = useState('');
	const [phone, setPhone] = useState(0);
	const [mail, setMail] = useState('');
	const [msg, setMsg] = useState('');
	const [contactData, setContactData] = useState({});
	const [bannerData, setBannerData] = useState({});
	const [latLng, setLatLng] = useState([]);
	const [loadedLatLng, setLoadedLatLng] = useState(false);

	useEffect(() => {
		getContactData();
	}, []);

	const getContactData = async() => {
		await axios.get('auth/contact/contactCard')
		.then(response => {
			if (response.status === 200) {
				setContactData(response.data);
			}
		});
		await axios.get(`auth/general/banner`)
		.then(response => {
			setBannerData(response.data.data[2]);
		})
		await axios.get('auth/general/socialMedia')
		.then(response => {
			if(response.status === 200){
				setLatLng([response.data.latitude, response.data.longitude]);
				setLoadedLatLng(true);
			}
		})
	}
	
	const handleSubmit = () => {
		if( (name !== '') && (phone !== '') && (mail !== '') && (msg !== '')){
			axios.post(`contact/mail`, {
				name,
				email: mail,
				phone,
				message: msg
			})
			.then(response => {
				if(response.status === 200){
					swal("Correcto", "cotización enviada correctamente", "success");
				}
			});
		}
		else{
			swal("Oops!", "Es necesario que todos los campos se llenen para enviar la solicitud", "warning");
		}
	}

    return (
		<>
			<Header {...props} />
			<div
				className="d-flex justify-content-center align-items-center"
				style={Object.assign( {}, style.image, {backgroundImage: `url(${axios.baseURLIMG}${bannerData.image})`} )}
			>
				<p
					style={{
						color: "white",
						fontSize: "4vh",
						textAlign: "center"
					}}
					className="corsoBanner"
				>
					{" "}{bannerData.title}{" "}
				</p>
			</div>
			<Container fluid>
				<h1 className="corsoTitle" style={style.title}>Conoce nuestro punto de contacto</h1>
				<Row style={{ marginBottom: 20 }}>
					<Col xs="12" md="6">
						<p className="corsoTitle" style={style.text}>Déjanos tu mensaje</p>
						<Form onSubmit={(e) => {
							e.preventDefault()
							handleSubmit();              
						}}>
							<Col>
								<FormGroup>
									<label>Nombre</label>
									<Input
										name="Nombre"
										value={name}
										required={true}
										onChange={(e) => {
											setName(e.target.value);
										}}
										placeholder="Nombre"
										type="text"
									/>
								</FormGroup>
								<FormGroup>
									<label>Telefono</label>
									<Input
										name="Telefono"
										value={phone}
										required={true}
										onChange={(e) => {
											setPhone(e.target.value);
										}}
										placeholder="Telefono"
										type="text"
									/>
								</FormGroup>
								<FormGroup>
									<label>Correo Electronico</label>
									<Input
										name="Correo Electronico"
										value={mail}
										required={true}
										onChange={(e) => {
											setMail(e.target.value);
										}}
										placeholder="Correo Electronico"
										type="text"
									/>
								</FormGroup>
								<FormGroup>
									<label>Mensaje</label>
									<Input
										name="Mensaje"
										value={msg}
										required={true}
										onChange={(e) => {
											setMsg(e.target.value);
										}}
										placeholder="..."
										cols="80"
										rows="4"
										type="textarea"
									/>
								</FormGroup>
							</Col>
							<Row
								className="justify-content-end align-items-center"
								style={{ paddingRight: 30 }}
							>
								<Button type="submit" >
									Enviar
								</Button>
							</Row>
						</Form>
					</Col>
					<Col xs="12" md="6">
						<p className="corsoTitle" style={style.text}>Más información</p>
						<Row className="m-sm m-md-0">
							<Col
								xs="2"
								className="d-flex align-content-center align-items-center"
							>
								<FaMapMarkerAlt
									className="align-self-center"
									size={50}
									color="black"
									style={{ textAlign: "center" }}
								/>
							</Col>
							<Col xs="10">
								<p className="corsoTitle titleShadowWhite" style={style.info}>visitanos en...</p>
								<p>
									{contactData.address}
								</p>
							</Col>
						</Row>
						<Row className="m-sm m-md-0">
							<Col
								xs="2"
								className="d-flex align-content-center align-items-center"
							>
								<FaPhone
									className="align-self-center"
									size={50}
									color="black"
									style={{ textAlign: "center" }}
								/>
							</Col>
							<Col xs="10">
								<p className="corsoTitle titleShadowWhite" style={style.info}>Llamanos al...</p>
								<p>{contactData.phone_number}</p>
							</Col>
						</Row>
						<Row className="m-sm m-md-0">
							<Col
								xs="2"
								className="d-flex align-content-center align-items-center"
							>
								<IoMdMail
									className="align-self-center"
									size={50}
									color="black"
									style={{ textAlign: "center" }}
								/>
							</Col>
							<Col xs="10">
								<p className="corsoTitle titleShadowWhite" style={style.info}>Escribenos a...</p>
								<p> {contactData.email} </p>
							</Col>
						</Row>
					</Col>
				</Row>
				{/* <OnBuild /> */}

				{loadedLatLng &&
					<MapContainer style={{width: '100%', height: 400, marginBottom: 40}} center={latLng} zoom={13} scrollWheelZoom={false}>
						<TileLayer
							attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
							url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
						/>
						<Marker position={latLng}>
							<Popup>
								A pretty CSS3 popup. <br /> Easily customizable.
							</Popup>
						</Marker>
					</MapContainer>
				}
			</Container>
			<Footer {...props} />
		</>
	);
}

const style = {
    title: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 10,
		color: '#426fb8',
		textShadow: "-1px 0px 2px black, 0 1px black, 1px 0 black, 0 -1px black"
    },
	image: {
        height: "50vh",
        minWidth: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    text: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 10,
		textShadow: "-1px 0px 8px white, 0 1px white, 1px 0 white, 0 -1px white"
    },
    info: {
        marginTop: 10,
        marginBottom: 10
    }
}

export default Contacto
