import React, {useEffect, useState} from 'react'
import {
	Row,
    Container,
	Col
} from "reactstrap";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import corso_horizontal from 'assets/img/LOGO_CORSO_HORIZONTAL.png';

//iconos
import { GiStrong } from 'react-icons/gi';
import { FaHandshake, FaUserShield } from "react-icons/fa";
import { GrOptimize } from 'react-icons/gr';
//axios
import axios from 'utils/AxiosHelper';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const Contacto = (props) => {

	//state
	const [modules, setModules] = useState([
		{
			title: "Inicios",
			description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tincidunt suscipit erat eget posuere. Praesent"
		},
		{
			title: "Misión",
			description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tincidunt suscipit erat eget posuere. Praesent"
		},
		{
			title: "Visión",
			description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tincidunt suscipit erat eget posuere. Praesent"
		},
	]);
	const [bannerData, setBannerData] = useState({});
	const [latLng, setLatLng] = useState([]);
	const [loadedLatLng, setLoadedLatLng] = useState(false);

	useEffect(() => {
		getAboutUsData();
	}, []);

	const getAboutUsData = async() => {
		await axios.get('auth/aboutUs/presentationCards')
		.then(response => {
			setModules(response.data.data);
		})

		await axios.get(`auth/general/banner`)
		.then(response => {
			setBannerData(response.data.data[0]);
		})
		await axios.get('auth/general/socialMedia')
		.then(response => {
			if(response.status === 200){
				setLatLng([response.data.latitude, response.data.longitude]);
				setLoadedLatLng(true);
			}
		})
	}

    return (
		<>
			<Header {...props} />
			<div
				className="d-flex justify-content-center align-items-center"
				style={Object.assign( {}, style.image, {backgroundImage: `url(${axios.baseURLIMG}${bannerData.image})`} )}
			>
				<p style={{color: "white",fontSize: "4vh",textAlign: "center",fontWeight: "bold"}} className="corsoBanner" >
					{" "}{bannerData.title}{" "}
				</p>
			</div>
			<Container
				fluid
				className="d-flex justify-content-center align-items-center flex-column"
				style={{ padding: 0, margin: 0 }}
			>
				<div style={style.divCard}>
					<h2
						className="corsoTitle"
						style={{
							textAlign: "center",
							color: "white"
						}}
					>
						{modules[0].title}
					</h2>
					<p style={{ color: "white" }}>
						{modules[0].description}
					</p>
				</div>

				<img
					alt=""
					src={corso_horizontal}
					style={{ height: 100, marginTop: 10, marginBottom: 10 }}
				/>
				<Row className="justify-content-around align-items-center">
					<Col style={style.thirdCard} xs="11" md="5">
						<h3
							style={{
								textAlign: "center",
								color: "white"
							}}
							className="corsoTitle"
						>
							{modules[1].title}
						</h3>
						<p style={{ color: "black" }}>
							{modules[1].description}
						</p>
					</Col>
					<Col style={style.thirdCard} xs="11" md="5">
						<h3
							style={{
								textAlign: "center",
								color: "white",
							}}
							className="corsoTitle"
						>
							{modules[2].title}
						</h3>
						<p style={{ textAlign: "right"}}>
							{modules[2].description}
						</p>
					</Col>
				</Row>

				<h1 className="corsoTitle" style={{ color: "white", textShadow: "-1px 0px 8px black, 0 1px black, 1px 0 black, 0 -1px black;" }}>Valores</h1>

				<Row
					className="justify-content-around align-items-center"
					style={{ width: "80%" }}
				>
					<Col className="d-flex justify-content-center align-items-center flex-column" >
						<FaUserShield
							className="align-self-center"
							size={60}
							color="black"
							style={{ textAlign: "center" }}
						/>
						<p>Honestidad</p>
					</Col>
					<Col className="d-flex justify-content-center align-items-center flex-column" >
						<FaHandshake
							className="align-self-center"
							size={60}
							color="black"
							style={{ textAlign: "center" }}
						/>
						<p>Compromiso</p>
					</Col>
					<Col className="d-flex justify-content-center align-items-center flex-column" >
						<GrOptimize
							className="align-self-center"
							size={60}
							color="black"
							style={{ textAlign: "center" }}
						/>
						<p>Crecimiento</p>
					</Col>
					<Col className="d-flex justify-content-center align-items-center flex-column" >
						<GiStrong
							className="align-self-center"
							size={60}
							color="black"
							style={{ textAlign: "center" }}
						/>
						<p>Esfuerzo</p>
					</Col>
				</Row>

				<h1 className="corsoTitle titleShadowWhite" style={{marginTop: 20, marginBottom: 20}} >¡Visítanos en nuestra sucursal!</h1>

				{loadedLatLng &&
					<MapContainer style={{width: '100%', height: 400, marginBottom: 40}} center={latLng} zoom={13} scrollWheelZoom={false}>
						<TileLayer
							attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
							url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
						/>
						<Marker position={latLng}>
							<Popup>
								A pretty CSS3 popup. <br /> Easily customizable.
							</Popup>
						</Marker>
					</MapContainer>
				}
			</Container>
			<Footer {...props} />
		</>
	);
}

const style = {
	image: {
		backgroundImage:
			"url(" + require("assets/img/nosotros/nosotros.jpg") + ")",
		height: "50vh",
		minWidth: "100%",
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
	divCard: {
		backgroundColor: "#426fb8",
		height: "30vh",
		minHeight: "300px",
		maxHeight: "500px",
		width: "60vw",
		alignSelf: "center",
		padding: "5%",
        borderRadius: 20,
        marginTop: -80
	},
	secondCard: {
		backgroundColor: "#426fb8",
		height: "30vh",
		minHeight: "300px",
		maxHeight: "500px",
		width: "60vw",
		alignSelf: "center",
		padding: "5%",
		borderRadius: 20,
	},
	thirdCard: {
		backgroundColor: "#BFC6D8",
		height: "30vh",
        marginTop: 40,
        marginBottom: 40,
		minHeight: "300px",
		maxHeight: "500px",
		width: "60vw",
		alignSelf: "center",
		padding: "5%",
		borderRadius: 20,
	},
};

export default Contacto
