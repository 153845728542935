import React, { useState, useEffect } from 'react';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Button
} from "reactstrap";

import axios from 'utils/AxiosHelper';
import swal from 'sweetalert';

import Table from 'components/CutomTable/CustomTable';
import CustomModal from 'components/CustomModal';
import CategorieForm from './ProductForm';

const cols = [
    { accessor: "id", header: "ID" },
    { accessor: "title", header: "Nombre" },
    { accessor: "top", header: "Top" },
];

const Products = (props) => {

    //state
    const [products, setProducts] = useState([]);
    const [currenrCategory, setCurrenrCategory] = useState({});
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        axios.get('auth/product')
            .then(response => {
                if (response.status === 200) {
                    setProducts(response.data);
                }
            })
    }

    const onDelete = async (item) => {
        await swal({
            title: "¿Estás seguro?",
            text: "Una vez eliminado no se podra recuperar el registro",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios.post(`auth/product/delete/${item.id}`, { name: item.name }, 'delete')
                        .then(response => {
                            if (response.status === 200) {
                                swal("Registro eliminado correctamente", { icon: "success" });
                            }
                        })
                    // axios.del(`auth/product/delete/${item.id}`)
                    //     .then(response => {
                    //         if (response.status === 200) {
                    //             swal("Registro eliminado correctamente", { icon: "success" });
                    //         }
                    //     })
                }
            });
        getData();
    }

    const onEdit = (item) => {
        setCurrenrCategory(item);
        setShowForm(true);
    }

    const onAddNewCategory = () => {
        setCurrenrCategory({});
        setShowForm(true);
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        {/* Tarjeta para editar las tarjetas del Menu de inicio */}
                        <Card>
                            <CardHeader className="mb-5">
                                <CardTitle tag="h3">Productos</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    cols={cols}
                                    rows={products}
                                    actions
                                    showAction={false}
                                    onDelete={item => onDelete(item)}
                                    onEdit={item => onEdit(item)}
                                />
                                {/* <Form>
                                    <Col>
                                        <CardTitle tag="h4">Cinta informativa</CardTitle>
                                        <FormGroup>
                                            <label>Titulo</label>
                                            <Input
                                                name="inicios" value={'inicios'}
                                                onChange={(e) => { this.onChageData(e); }}
                                                placeholder="Titulo" type="text"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>descripcion</label>
                                            <Input
                                                name="iniciosmsg" value={'iniciosmsg'}
                                                onChange={(e) => { this.onChageData(e); }}
                                                placeholder="..." cols="80" rows="4" type="textarea"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Form> */}
                            </CardBody>
                            <CardFooter>
                                <Button
                                    onClick={onAddNewCategory} className="btn-fill" color="primary"
                                >
                                    Añadir nuevo producto
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
            <CustomModal
                open={showForm}
                handleClose={() => setShowForm(false)}
            >
                <CategorieForm category={currenrCategory} />
            </CustomModal>
        </>
    )
}

export default Products;