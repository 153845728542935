import React, { useState, useEffect } from "react";
import {
	FormGroup,
	Form,
	Input,
	Row,
	Container,
	Col,
	Button,
} from "reactstrap";
import axios from 'utils/AxiosHelper';
import swal from 'sweetalert';

import { FaWpforms, FaCheckCircle, FaPhone, FaTruck } from "react-icons/fa";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import LogoCorso from "assets/img/LOGO_CORSO.png";


const Cotiza = (props) => {
	const [name, setName] = useState("");
	const [phone, setPhone] = useState(0);
	const [mail, setMail] = useState("");
	const [state, setState] = useState('');
	const [city, setCity] = useState('');
	const [municipio, setMunicipio] = useState('');
	const [postalCode, setPostalCode] = useState(0);
	const [type, setType] = useState('');
	const [specifications, setSpecifications] = useState("");
    const [categories, setCategories] = useState([]);
	const [bannerData, setBannerData] = useState({});

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
        await axios.get('auth/product/productCategory')
        .then(response => {
            if(response.status === 200){
                setCategories(response.data.data);
            }
        })
		await axios.get(`auth/general/banner`)
		.then(response => {
			setBannerData(response.data.data[4]);
		})
    }

	const handleSubmit = () => {
		axios.post(`budget/mail`,
			{
				name,
				email: mail,
				phone,
				country: state,
				state: city,
				city: municipio,
				pc: postalCode,
				product: type,
				specification: specifications
			}
		)
		.then(response => {
			if (response.status === 200) {
				swal("Correcto", "Petición enviada de manera correcta", "success");	
			}
        })
	}

	return (
		<>
			<Header {...props} />
			<div
				className="d-flex justify-content-center align-items-center"
				style={Object.assign( {}, style.image, {backgroundImage: `url(${axios.baseURLIMG}${bannerData.image})`} )}
			>
				<p style={{color: "white",fontSize: "4vh",textAlign: "center",fontWeight: "bold"}} className="corsoBanner" >
					{" "}{bannerData.title}{" "}
				</p>
			</div>
			<Container fluid>
				<h1 className="corsoTitle " style={style.title}>Proceso de cotización</h1>
				<Row
					style={{ width: "100vw" }}
					className="justify-content-around align-items-center"
				>
					<Col className="d-flex flex-column justify-content-center align-items-center">
						<FaWpforms
							size={80}
							color="grey"
							style={{ textAlign: "center" }}
						/>
						<p style={style.textB} >Llena el formulario</p>
					</Col>
					<Col className="d-flex flex-column justify-content-center align-items-center">
						<FaCheckCircle
							size={80}
							color="green"
							style={{ textAlign: "center" }}
						/>
						<p style={style.textB} >Validaremos su solicitud</p>
					</Col>
					<Col className="d-flex flex-column justify-content-center align-items-center">
						<FaPhone
							size={80}
							color="green"
							style={{ textAlign: "center" }}
						/>
						<p style={style.textB} >Confirmación del pedido</p>
					</Col>
					<Col className="d-flex flex-column justify-content-center align-items-center">
						<FaTruck
							size={80}
							color="#68BBAB"
							style={{ textAlign: "center" }}
						/>
						<p style={style.textB} >Listo para entregar</p>
					</Col>
				</Row>
				<Row style={{ marginBottom: 20 }}>
					<Col xs="12" md="6">
						<p className="corsoTitle titleShadowWhite" style={style.text}>Solicitud</p>
						<Form onSubmit={(e) => {
							e.preventDefault()
							handleSubmit();              
						}}>
							<Col>
								<FormGroup>
									<label>Nombre</label>
									<Input
										required={true}
										name="Nombre"
										value={name}
										onChange={(e) => {
											setName(e.target.value);
										}}
										placeholder="Nombre"
										type="text"
									/>
								</FormGroup>
								<FormGroup>
									<label>Telefono</label>
									<Input
										name="Telefono"
										required={true}
										value={phone}
										onChange={(e) => {
											setPhone(e.target.value);
										}}
										placeholder="Telefono"
										type="text"
									/>
								</FormGroup>
								<FormGroup>
									<label>Correo Electronico</label>
									<Input
										name="Correo Electronico"
										value={mail}
										required={true}
										onChange={(e) => {
											setMail(e.target.value);
										}}
										placeholder="Correo Electronico"
										type="text"
									/>
								</FormGroup>
								<FormGroup>
									<label>País</label>
									<Input
										name="País"
										value={state}
										required={true}
										onChange={(e) => {
											setState(e.target.value);
										}}
										placeholder="México"
										type="text"
									/>
								</FormGroup>
								<FormGroup>
									<label>Estado</label>
									<Input
										name="Estado"
										value={city}
										required={true}
										onChange={(e) => {
											setCity(e.target.value);
										}}
										placeholder="Jalisco"
										type="text"
									/>
								</FormGroup>
								<FormGroup>
									<label>Municipio</label>
									<Input
										name="Municipio"
										value={municipio}
										required={true}
										onChange={(e) => {
											setMunicipio(e.target.value);
										}}
										placeholder="Zapopan"
										type="text"
									/>
								</FormGroup>
								<FormGroup>
									<label>Código Postal</label>
									<Input
										name="Código Postal"
										value={postalCode}
										required={true}
										onChange={(e) => {
											setPostalCode(e.target.value);
										}}
										placeholder="45234"
										type="text"
									/>
								</FormGroup>
								<FormGroup>
									<label>
										Tipo de Producto (Galletas, barras, etc)
									</label>
									<Input
										type="select"
										name="select"
										required={true}
										value={type}
										onChange={(e) => {
											setType(e.target.value);
										}}
										id="exampleSelect"
									>
										{categories.map((category) => {
											return (
												<option value={category.name} > {category.name} </option>
											);
										})}
									</Input>
								</FormGroup>
								<FormGroup>
									<label>Especificiones</label>
									<Input
										name="Especificiones"
										required={true}
										value={specifications}
										onChange={(e) => {
											setSpecifications(e.target.value);
										}}
										placeholder="..."
										cols="80"
										rows="4"
										type="textarea"
									/>
								</FormGroup>
							</Col>
							<Row
								className="justify-content-end align-items-center"
								style={{ paddingRight: 30 }}
							>
								<Button outline color="primary" type ="submit">
									Enviar
								</Button>
							</Row>
						</Form>
					</Col>
					<Col
						xs="12"
						md="6"
						className="d-flex flex-column justify-content-center align-items-center"
					>
						<img
							alt=""
							src={LogoCorso}
							style={{ maxWidth: "30vw" }}
						/>
					</Col>
				</Row>
			</Container>
			<Footer {...props} />
		</>
	);
};

const style = {
	title: {
		textAlign: "center",
		marginTop: 10,
		marginBottom: 10,
		fontWeight: "bold",
		color: "#426fb8",
		textShadow: "-1px 0px 2px black, 0 1px black, 1px 0 black, 0 -1px black"
	},
	image: {
		backgroundImage:
			"url(" + require("assets/img/cotiza/cotiza.png") + ")",
		height: "50vh",
		minWidth: "100%",
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
	text: {
		textAlign: "center",
		marginTop: 10,
		marginBottom: 10,
		fontWeight: "bold",
		fontSize: 40
	},
	textB: {
		textAlign: 'center',
		marginTop: 10,
		fontWeight: '600',
		marginBottom: 20
	}
};

export default Cotiza;
