import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { ImWhatsapp } from 'react-icons/im';

//Components
import Producto from 'screens/Productos/Producto';
import routes from "routes.js";

var ps;

class HomeLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		document.body.classList.add("white-content");
	}

	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			document.documentElement.className += " perfect-scrollbar-on";
			document.documentElement.classList.remove("perfect-scrollbar-off");
			ps = new PerfectScrollbar(this.refs.mainPanel, {
				suppressScrollX: true,
			});
			let tables = document.querySelectorAll(".table-responsive");
			for (let i = 0; i < tables.length; i++) {
				ps = new PerfectScrollbar(tables[i]);
			}
		}
	}

	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
			document.documentElement.className += " perfect-scrollbar-off";
			document.documentElement.classList.remove("perfect-scrollbar-on");
		}
	}

	componentDidUpdate(e) {
		if (e.history.action === "PUSH") {
			if (navigator.platform.indexOf("Win") > -1) {
				let tables = document.querySelectorAll(".table-responsive");
				for (let i = 0; i < tables.length; i++) {
					ps = new PerfectScrollbar(tables[i]);
				}
			}
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			this.refs.mainPanel.scrollTop = 0;
		}
	}

	openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

	getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === "/") {
				return (
					<Route
						path={ prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};

	render() {
		return (
			<>
				<div className="wrapper">
					<div className="main-panel" style={{borderTop: 0}} ref="mainPanel">
						<Switch>
							<Route path="/productos/:producId" render={(props) => <Producto {...props} />} />
							{this.getRoutes(routes)}
							<Redirect from="*" to="/inicio" />
						</Switch>
					</div>
					<div style={{ display:'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', left: 20, bottom: 20, height: 50, width: 50, backgroundColor: "green", borderRadius: 100 }} >
						<ImWhatsapp size={30} color="white" style={{cursor: 'pointer'}} onClick={() => {this.openInNewTab('https://wa.me/message/BB7QW2UELL72G1')}} />
					</div>
				</div>
			</>
		);
	}
}

export default HomeLayout;
