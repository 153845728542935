import React, {useState, useEffect} from 'react';
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Row,
    Col,
	FormGroup,
	Input,
    Button
} from "reactstrap";

import axios from 'utils/AxiosHelper';
import swal from 'sweetalert';

import Table from 'components/CutomTable/CustomTable';
import CustomModal from 'components/CustomModal';
import CategorieForm from './CarouselForm';

const cols = [
    {accessor: "id", header: "ID"},
    {accessor: "title", header: "Nombre"},
    {accessor: "url", header: "URL VIdeo"},
    
];

const Carousel = (props) => {

    //state
    const [currenrCategory, setCurrenrCategory] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [carousel, setCarousel] = useState([]);
    const [isVideo, setIsVideo] = useState(false);
    const [urlVideo, setUrlVideo] = useState('');
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        axios.get('auth/home/slider')
        .then(response => {
            if (response.status === 200) {
                setCarousel(response.data.data);
            }
        })
    }

    const onShowCategory = (item) => {
        //TODO:
    }

    const onDelete = async(item) => {
        await swal({
            title: "¿Estás seguro?",
            text: "Una vez eliminado no se podra recuperar el registro",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                axios.post(`auth/home/slider/${item.id}`, {name: item.name}, 'delete')
                .then(response => {
                    if(response.status === 200){
                        swal("Registro eliminado correctamente", {icon: "success"});
                    }
                })
            }
        });
        getData();
    }

    const onEdit = (item) => {
        setUpdate(true);
        setCurrenrCategory(item);
        if (item.url !== null) {
            setUrlVideo(item.url)
            setIsVideo(true);
        }
        else {
            setShowForm(true);
        }
    }

    const onAddNewCategory = async() => {
        await setCurrenrCategory({});
        await setShowForm(true);
    }

    const onAddNewVideo = async() => {
        await setIsVideo(true);
        await setCurrenrCategory({});
    }

    const closeForm = () => {
        setShowForm(false);
        setIsVideo(false);
        getData();
    }

    const onAddVideo = () => {
        if (update) {
            axios.post(`auth/home/slider/${currenrCategory.id}`, {url: urlVideo}, 'put')
            .then(response => {
                if(response.status === 200){
                    swal("Correcto", "Agregado correctamente", "success");
                }
            })
        }
        else {
            axios.post("auth/home/slider", {url: urlVideo})
            .then(response => {
                if(response.status === 200){
                    swal("Correcto", "Agregado correctamente", "success");
                }
            })
        }
        closeForm();
	};
    
    return(
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        {/* Tarjeta para editar las tarjetas del Menu de inicio */}
                        <Card>
                            <CardHeader className="mb-5">
                                <CardTitle tag="h3">Carrusel</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table 
                                    cols={cols}
                                    rows={carousel}
                                    actions
                                    showAction={false}
                                    onShow={item => onShowCategory(item)}
                                    onDelete={item => onDelete(item)}
                                    onEdit={item => onEdit(item)}
                                />
                            </CardBody>
                            <CardFooter>
                                <Button
                                    onClick={onAddNewCategory} className="btn-fill" color="primary"
                                >
                                    Añadir nuevo slider
                                </Button>
                                <Button
                                    onClick={onAddNewVideo} className="btn-fill" color="primary"
                                >
                                    Añadir nuevo video
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
            <CustomModal
                open={showForm}
                handleClose={() => setShowForm(false)}
            >
                <CategorieForm category={currenrCategory} closeForm={closeForm} />
            </CustomModal>
            <CustomModal
                open={isVideo}
                handleClose={closeForm}
            >
                <FormGroup>
                    <label>URL</label>
                    <Input
                        style={{color: 'black'}}
                        name="title"
                        value={urlVideo}
                        onChange={(e) => {setUrlVideo(e.target.value)}}
                        placeholder="..."
                    />
                </FormGroup>
                <Button onClick={onAddVideo} className="btn-fill" color="primary" >
                { update? 'Actualizar':'Agregar'}
                </Button>
            </CustomModal>
        </>
    )
}

export default Carousel;