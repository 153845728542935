/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from 'redux/configureStore';
import 'leaflet/dist/leaflet.css'

const store = configureStore();

ReactDOM.render(
	<Provider store={store.store}>
	  <PersistGate loading={null} persistor={store.persistor}>
		<BrowserRouter>
		  <App />
		</BrowserRouter>
	  </PersistGate>
	</Provider>,
	document.getElementById("root")
);
