import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={20}
        defaultCenter={{ lat: 20.557369, lng: -103.450105 }}
    >
        {props.isMarkerShown && <Marker position={{ lat: 20.557369, lng: -103.450105 }} />}
    </GoogleMap>
));

export default MapComponent;