import React, {useState, useEffect} from 'react'

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import { GrChatOption } from 'react-icons/gr';
import { GiGears } from 'react-icons/gi';
import { BsCheckCircle } from 'react-icons/bs'
import { FaRegRegistered } from 'react-icons/fa';
import { BiLike } from 'react-icons/bi';
import LogoCorso from "assets/img/LOGO_CORSO.png";

import Cookie from 'assets/img/innovacion/cookie.png'
import Bowl from 'assets/img/innovacion/bowl.png'

import { Row, Container, Col } from "reactstrap";
import axios from 'utils/AxiosHelper';

const cards = [
	{
		title: "Analizamos en conjunto tu idea para hacerla realidad",
	},
	{
		title: "Comienza la etapa de producción y fabricación de tu producto",
	},
	{
		title: "El producto queda finalizado y se entrega al cliente",
	},
];

const Innovacion = (props) => {

	//state
	const [proccessInnovationData, setProccessInnovationData] = useState({});
	const [bannerData, setBannerData] = useState({});
	const [innovationCards, setInnovationCards] = useState({});
	const [loadedData, setloadedData] = useState(false)

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		axios.get('auth/innovation/presentationCardsInnovation')
		.then(response => {
			if (response.status === 200) {
				setProccessInnovationData(response.data);
			}
		})
		await axios.get('auth/innovation/processInnovation')
		.then(response => {
			if (response.status === 200) {
				var aux = [
					{
						title: response.data.title_one,
						description: response.data.description_one,
						icon: (
							<GrChatOption
								className="align-self-center"
								size={100}
								color="black"
								style={{ textAlign: "center" }}
							/>
						),
					},
					{
						title: response.data.title_two,
						description: response.data.description_two,
						icon: (
							<GiGears
								className="align-self-center"
								size={100}
								color="black"
								style={{ textAlign: "center" }}
							/>
						),
					},
					{
						title: response.data.title_three,
						description: response.data.description_three,
						icon: (
							<BsCheckCircle
								className="align-self-center"
								size={100}
								color="black"
								style={{ textAlign: "center" }}
							/>
						),
					}
				];
				setInnovationCards(aux);
			}
		})
		await axios.get(`auth/general/banner`)
		.then(response => {
			setBannerData(response.data.data[3]);
		})
		setloadedData(true);
	}

    return (
		<>
			<Header {...props} />
			<div
				className="d-flex justify-content-center align-items-center"
				style={Object.assign( {}, style.image, {backgroundImage: `url(${axios.baseURLIMG}${bannerData.image})`} )}
			>
				<p style={{color: "white",fontSize: "4vh",textAlign: "center",fontWeight: "bold"}} className="corsoBanner" >
					{" "}{bannerData.title}{" "}
				</p>
			</div>

				{loadedData &&
					<Container
						fluid
						className="d-flex justify-content-center align-items-center flex-column"
						style={{ padding: 0, margin: 0 }}
					>
						<Row
							style={style.divCard}
							className="d-flex justify-content-center align-items-center flex-column"
						>
							<h2
								className="corsoTitle"
								style={{
									textAlign: "center",
									color: "white",
									fontSize: "2rem",
								}}
							>
								{proccessInnovationData.title_one}
							</h2>
							<p style={{ color: "white", fontSize: "1rem" }}>
								{proccessInnovationData.description_one}
							</p>
						</Row>

						<Row
							style={{ width: "100vw", marginTop: 25, marginBottom: 25 }}
						>
							<Col
								xs="6"
								style={{
									minHeight: 300,
									width: "50vw",
									backgroundImage:
										"url(" +
										require("assets/img/innovacion/innovacion-middle.png") +
										")",
									backgroundSize: "cover",
									backgroundPosition: "center",
									borderRadius: "0px 20px 20px 0px",
								}}
							></Col>
							<Col
								xs="6"
								style={{
									minHeight: 300,
									width: "50vw",
									backgroundColor: "#FCCA24",
									borderRadius: "20px 0px 0px 20px",
								}}
							>
								<Row>
									<p
										className="corsoTitle"
										style={{
											textAlign: "center",
											color: "white",
											fontSize: "1.5rem",
											padding: "5%",
										}}
									>
										{proccessInnovationData.description_two}
									</p>
								</Row>
								<Row className="justify-content-around">
									<img
										alt=""
										src={Cookie}
										style={{ maxHeight: 80 }}
									/>
									<img alt="" src={Bowl} style={{ maxHeight: 80 }} />
								</Row>
							</Col>
						</Row>

						<h2
							className="corsoTitle"
							style={{
								fontSize: "6vw",
								color: "#426fb8",
								textShadow: "-1px 0px 2px black, 0 1px black, 1px 0 black, 0 -1px black"
							}}
						>
							NUESTRO FUNCIONAMIENTO
						</h2>

						<Row className="justify-content-around" style={{width: '100%'}}>
							{innovationCards.map((item) => {
								return (
									<Col
										style={style.card}
										xs="9"
										md="3"
										className="d-flex justify-content-center align-items-center flex-column"
									>
										<div
											style={{ height: "60%" }}
											className="d-flex justify-content-center align-items-center"
										>
											{item.icon}
										</div>
										<p
											style={{
												height: "40%",
												fontWeight: "400",
												paddingLeft: "10%",
												paddingRight: "10%",
												fontSize: 20,
												textAlign: "center",
											}}
										>
											{item.description}
										</p>
									</Col>
								);
							})}
						</Row>

						<Row style={{ width: "100vw" }}>
							<Col xs="12" md="6" style={{ height: 200 }}>
								<Row
									className="justify-content-start align-items-center"
									style={{
										backgroundColor: "#426fb8",
										height: "33%",
									}}
								>
									<p
										className="corsoTitle"
										style={{
											fontSize: 32,
											paddingLeft: 10,
											color: "white",
											textShadow: '0 0 black'
										}}
									>
										TU PROPIA MARCA
									</p>
									<BsCheckCircle
										color="black"
										style={{ marginLeft: 10 }}
										className="align-self-center"
										size={53}
									/>
								</Row>
								<Row
									className="justify-content-start align-items-center"
									style={{ height: "33%" }}
								>
									<p
										className="corsoTitle"
										style={{
											fontSize: 31,
											paddingLeft: 10,
											fontWeight: "bold",
											textShadow: "0 0 black"
										}}
									>
										LA MEJOR CALIDAD
									</p>
									<FaRegRegistered
										color="black"
										style={{ marginLeft: 10 }}
										className="align-self-center"
										size={50}
									/>
								</Row>
								<Row
									className="justify-content-start align-items-center"
									style={{
										backgroundColor: "#426fb8",
										height: "33%",
									}}
								>
									<p
										className="corsoTitle"
										style={{
											fontSize: 30,
											paddingLeft: 10,
											color: "white",
											fontWeight: "bold",
											textShadow: "0 0 black"
										}}
									>
										TU MEJOR OPCIÓN
									</p>
									<BiLike
										color="black"
										style={{ marginLeft: 10 }}
										className="align-self-center"
										size={45}
									/>
								</Row>
								<div style={style.triangle}></div>
							</Col>
							<Col
								xs="12"
								md="6"
								className="d-flex justify-content-center align-items-center"
							>
								<img
									alt=""
									src={LogoCorso}
									style={{ maxWidth: "20vw" }}
								/>
							</Col>
						</Row>
					</Container>
				}

			<Footer {...props} />
		</>
	);
}

const style = {
	image: {
		backgroundImage:
			"url(" + require("assets/img/innovacion/header.png") + ")",
		backgroundSize: "cover",
		backgroundPosition: "center",
		height: "50vh",
		minWidth: "100%",
	},
	divCard: {
		backgroundColor: "#426fb8",
		height: "20vh",
		minHeight: "300px",
		maxHeight: "500px",
		width: "70vw",
		alignSelf: "center",
		padding: "5%",
		borderRadius: 20,
		marginTop: -80,
	},
	card: {
		height: "50vh",
		borderWidth: 1,
		borderColor: "black",
		borderStyle: "solid",
		borderRadius: "20rem",
		marginBottom: 20,
	},
	triangle: {
		position: "absolute",
		top: 0,
		bottom: 0,
		right: 0,
		borderColor: "transparent #f5f6fa",
		borderStyle: "solid",
		borderWidth: "200px 14vw 0px 0px ",
	},
};

export default Innovacion
