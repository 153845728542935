import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  MenuItem,
  makeStyles
} from '@material-ui/core';
//@material-ui icons
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  table: {
    backgroundColor: '#1e1e2f !important',
    borderColor: '#1e1e2f !important',
    color: 'white !important'
  },
  cell: {
    color: 'white'
  }
}));

const CustomTable = (props) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setAllRows(props.rows);
    setRows(allRows.slice(start, limit));
    setLoaded(true);
  }, [])

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setStart(0);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setStart((newPage*limit)-1);
  };

  return (
    <Card
      className={clsx(classes.root)}
    >
    {loaded &&
      <PerfectScrollbar>
        <Box minWidth={400}>
          <Table className={clsx(classes.table)} >
            <TableHead>
              <TableRow style={{color: 'white'}}>
                {
                  props.cols.map((col, key) => {
                    return (
                      <TableCell className={clsx(classes.cell)} key={`${key}`}>
                        {col.header}
                      </TableCell>
                    )
                  })
                }
                {props.actions && <TableCell className={clsx(classes.cell)} padding="checkbox" style={{textAlign: 'center'}}>
                  Acciones
                </TableCell>}
                {props.select && <TableCell style={{textAlign: 'center'}}>
                  {props.selectorTitle}
                </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.rows.map((customer, key) => {
                if( (key >= start) && (key < ((page===0)? limit: limit*(page+1))) ){
                  return(
                    <TableRow
                      hover
                      style={{color: 'white'}}
                      key={customer.id}
                      selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                    >
                      {
                        props.cols.map((item, key) => {
                          return (
                            <TableCell className={clsx(classes.cell)} key={`${key}`}>
                              {customer[props.cols[key].accessor]}
                            </TableCell>
                          )
                        })
                      }
                      {props.actions && <TableCell padding="checkbox">
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                            {props.showAction &&
                            <IconButton onClick={() => {props.onShow(customer)}} color="primary" aria-label="upload picture" component="span">
                                <VisibilityIcon />
                            </IconButton>}
                            {props.updateAction &&
                            <IconButton onClick={() => {props.onEdit(customer)}} style={{color: 'white'}} aria-label="upload picture" component="span">
                                <EditIcon />
                            </IconButton>}
                            {props.delAction && 
                            <IconButton onClick={() => {props.onDelete(customer)}} style={{color: '#a20000'}} aria-label="upload picture" component="span">
                                <DeleteIcon />
                            </IconButton>}
                        </div>
                      </TableCell>}
                      {
                        props.select && 
                        <TableCell >
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                            <TextField id="select" select label={props.selectLabel} value={customer[props.selectAccesor]} onChange={(e) => props.onSelectChange(e, customer)} margin="normal" fullWidth >
                            {
                                props.selectOptions.map((item, key) => {
                                return(
                                    <MenuItem key={key} value={item.value} > {item.name} </MenuItem>
                                )
                                })
                            }
                            </TextField>
                          </div>
                        </TableCell>
                      }
                    </TableRow>
                  )
                }
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    }
      <TablePagination
        component="div"
        className={clsx(classes.table)}
        count={props.rows.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[50, 100, 200]}
      />
    </Card>
  );
};

CustomTable.defaultProps = {
  accessor: "header",
  actions: false,
  showAction: true,
  delAction: true,
  updateAction: true,
  select: false,
  selectAccesor: "value",
  selectorTitle: "Selector",
  selectLabel: "seleccione un valor"
}

CustomTable.propTypes = {
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  accessor: PropTypes.string,
  actions: PropTypes.bool,
  onShow: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onShowPDF: PropTypes.func,
  showAction: PropTypes.bool,
  delAction: PropTypes.bool,
  updateAction: PropTypes.bool,
  select: PropTypes.bool,
  selectOptions: PropTypes.array,
  onSelectChange: PropTypes.func,
  selectAccesor: PropTypes.string,
  selectorTitle: PropTypes.string,
  selectLabel: PropTypes.string
};

export default CustomTable;
