/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import routes from "routes.js";

// reactstrap components
import { Container, Row, Col, Nav, NavLink } from "reactstrap";
import logo from "assets/img/LOGO_CORSO_HORIZONTAL.png";
import { ImFacebook, ImTwitter, ImYoutube, ImInstagram, ImPhone } from "react-icons/im";
import axios from 'utils/AxiosHelper';

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contactData: {},
			socialMedia: {}
		};
		this.activeRoute.bind(this);
		this.getContactData();
	}

	getContactData = async () => {
		await axios.get('auth/contact/contactCard')
		.then(response => {
			if(response.status === 200){
				this.setState({contactData: response.data});
			}
		})
		await axios.get('auth/general/socialMedia')
		.then(response => {
			if(response.status === 200){
				this.setState({socialMedia: response.data});
			}
		})
	}

	// verifies if routeName is the one active (in browser input)
	activeRoute(routeName) {
		return this.props.location.pathname.indexOf(routeName) > -1
			? true
			: false;
	}

	openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

	render() {
		return (
			<footer>
				<Container className="footer" fluid style={styles.footer}>
					<Row
						className="justify-content-around align-content-center"
						style={{
							backgroundColor: "#426fb8",
							height: "50px",
							marginRight: 0,
						}}
					>
						<ImFacebook color="white" size={30} style={{cursor: 'pointer'}} onClick={() => {this.openInNewTab(`${this.state.socialMedia.facebook}`)}}  />
						<ImTwitter color="white" size={30} style={{cursor: 'pointer'}} onClick={() => {this.openInNewTab(`${this.state.socialMedia.twitter}`)}}  />
						<ImYoutube color="white" size={30} style={{cursor: 'pointer'}} onClick={() => {this.openInNewTab(`${this.state.socialMedia.youtube}`)}}  />
						<ImInstagram color="white" size={30} style={{cursor: 'pointer'}} onClick={() => {this.openInNewTab(`${this.state.socialMedia.instagram}`)}}  />
					</Row>
					<Row style={styles.footer2}>
						<Col
							xs="12"
							md="4"
							className="align-items-center justify-content-center"
						>
							<div className="logo-img pl-md-4 ">
								<img
									style={{ maxWidth: "200px" }}
									src={logo}
									alt="CORSO LOGO"
								/>
							</div>
						</Col>
						<Col xs="12" md="8" className="pl-5 pl-md-0">
							<Row>
								<Col xs="10" sm="6">
									<Nav className="ml-auto" navbar>
										{routes.map((prop, key) => {
											if (prop.redirect) return null;
											return (
												<li
													key={key}
													className="active"
												>
													<NavLink
														activeClassName="active"
														onClick={() => {
                                                    		this.props.history.push(prop.path);
														}}
														style={{cursor: 'pointer'}}
													>
														<p
															style={{
																color:
																	"#426fb8",
																textDecoration: this.activeRoute(
																	prop.path
																)
																	? "underline"
																	: "none",
																textDecorationThickness: 5,
																fontWeight:
																	"600",
															}}
														>
															{" "}
															{prop.name}
														</p>
													</NavLink>
												</li>
											);
										})}
									</Nav>
								</Col>
								<Col
									xs="10"
									sm="6"
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "space-around",
									}}
								>
									<Row className="align-items-start">
										<p>
											<i
												className="tim-icons icon-email-85"
												style={{ marginRight: 4 }}
											/>
											{this.state.contactData.email}
										</p>
									</Row>
									<Row className="align-items-start">
										<p>
											<ImPhone
												size={15}
												color="#707070"
											/>
											{this.state.contactData.phone_number}
										</p>
									</Row>
									<Row className="align-items-start">
										<p>
											<i
												className="tim-icons icon-square-pin"
												style={{ marginRight: 4 }}
											/>
											{this.state.contactData.address}
										</p>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</footer>
		);
	}
}

const styles = {
	footer: {
		padding: 0,
	},
	footer2: {
		marginTop: 10,
		marginBottom: 15,
		marginRight: 0,
	},
};

export default Footer;
