/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import axios from 'utils/AxiosHelper';
import swal from 'sweetalert';
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Row,
	Col,
	Button,
	FormGroup,
	Form,
	Input,
} from "reactstrap";

class Innovacion extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			formsCards: {
				tilecard1: "",
				msgcard1: "",
				tilecard2: "",
				msgcard2: "",
				tilecard3: "",
				msgcard3: "",
			},
			title_one: "",
			description_one: "",
			title_two: "",
			description_two: ""
		};
		this.getData();
	}

	getData = () => {
		axios.get('auth/innovation/processInnovation')
			.then(response => {
				if (response.status === 200) {
					this.setState({
						...this.state,
						formsCards: {
							...this.state.formsCards,
							msgcard1: response.data.description_one,
							msgcard2: response.data.description_two,
							msgcard3: response.data.description_three
						}
					})
				}
			});
		axios.get('auth/innovation/presentationCardsInnovation')
			.then(response => {
				if (response.status === 200) {
					this.setState({
						...this.state,
						title_one: response.data.title_one,
						description_one: response.data.description_one,
						title_two: response.data.title_two,
						description_two: response.data.description_two
					});
				}
			})
	}

	onChageData = (e) => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value
		})
	}

	/**
	 * Función para cambiar los datos del formulario
	 * @param {*} e evento
	 */
	onChangeCardsFormData = (e) => {
		this.setState({
			formsCards: {
				...this.state.formsCards,
				[e.target.name]: e.target.value,
			},
		});
	};

	/**
	 * Función para guardar las tarjetas
	 * @param {*} e evento
	 */
	onSaveCards = (e) => {
		e.preventDefault();
		axios.post(
			'auth/innovation/presentationCardsInnovation/1',
			{
				title_one: this.state.title_one,
				description_one: this.state.description_one,
				title_two: this.state.title_two,
				description_two: this.state.description_two
			}
			, 'put'
		)
			.then(response => {
				if (response.status === 200) {
					swal("Correcto", "Datos guardados correctamente", "success");
				}
			});
	};

	onSaveInformativeData = (e) => {
		e.preventDefault();

		axios.post(
			'auth/innovation/processInnovation/1',
			{
				title_one: "one",
				title_two: "two",
				title_three: "three",
				description_one: this.state.formsCards.msgcard1,
				description_two: this.state.formsCards.msgcard2,
				description_three: this.state.formsCards.msgcard3,
			}
			, 'put'
		)
			.then(response => {
				if (response.status === 200) {
					swal("Correcto", "Datos guardados correctamente", "success");
				}
			});
	}

	render() {
		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							{/* Tarjeta para agregar items al carrusel de inicio */}
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Cinta informativa
									</CardTitle>
								</CardHeader>
								<form onSubmit={(e) => { this.onSaveCards(e) }} >
									<CardBody>
										<FormGroup>
											<label>Titulo</label>
											<Input
												name="title_one"
												value={this.state.title_one}
												onChange={(e) => {
													this.onChageData(e);
												}}
												placeholder="Titulo"
												type="text"
											/>
										</FormGroup>
										<FormGroup>
											<label>descripcion</label>
											<Input
												name="description_one"
												value={this.state.description_one}
												onChange={(e) => {
													this.onChageData(e);
												}}
												placeholder="..."
												cols="80"
												rows="4"
												type="textarea"
											/>
										</FormGroup>
									</CardBody>
									<CardBody>
										<FormGroup>
											<label>Titulo</label>
											<Input
												name="title_two"
												value={this.state.title_two}
												onChange={(e) => {
													this.onChageData(e);
												}}
												placeholder="Titulo"
												type="text"
											/>
										</FormGroup>
										<FormGroup>
											<label>descripcion</label>
											<Input
												name="description_two"
												value={this.state.description_two}
												onChange={(e) => {
													this.onChageData(e);
												}}
												placeholder="..."
												cols="80"
												rows="4"
												type="textarea"
											/>
										</FormGroup>
									</CardBody>
									<CardFooter>
										<Button
											className="btn-fill"
											color="primary"
											type="submit"
										>
											Actualizar
										</Button>
									</CardFooter>
								</form>
							</Card>
							{/* Tarjeta para editar las tarjetas del Menu de inicio */}
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Tarjetas innovación
									</CardTitle>
								</CardHeader>
								<form onSubmit={(e) => { this.onSaveInformativeData(e) }} >
									<CardBody>
										<Col>
											<CardTitle tag="h4">Primer tarjeta</CardTitle>
											<FormGroup>
												<label>Mensaje</label>
												<Input
													name="msgcard1"
													value={this.state.formsCards.msgcard1}
													onChange={(e) => {
														this.onChangeCardsFormData(e);
													}}
													placeholder="..."
													cols="80"
													rows="4"
													type="textarea"
												/>
											</FormGroup>
										</Col>
										<Col>
											<CardTitle tag="h4">Segunda tarjeta</CardTitle>
											<FormGroup>
												<label>Mensaje</label>
												<Input
													name="msgcard2"
													value={this.state.formsCards.msgcard2}
													onChange={(e) => {
														this.onChangeCardsFormData(e);
													}}
													placeholder="..."
													cols="80"
													rows="4"
													type="textarea"
												/>
											</FormGroup>
										</Col>
										<Col>
											<CardTitle tag="h4">tercera tarjeta</CardTitle>
											<FormGroup>
												<label>Mensaje</label>
												<Input
													name="msgcard3"
													value={this.state.formsCards.msgcard3}
													onChange={(e) => {
														this.onChangeCardsFormData(e);
													}}
													placeholder="..."
													cols="80"
													rows="4"
													type="textarea"
												/>
											</FormGroup>
										</Col>
									</CardBody>
									<CardFooter>
										<Button
											className="btn-fill"
											color="primary"
											type="submit"
										>
											Actualizar
										</Button>
									</CardFooter>
								</form>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default Innovacion;
