import React, {useState, useEffect, useRef} from 'react'
import {
	Row,
    Container,
    Col,
	Button
} from "reactstrap";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

import ProducstList from './ProducstList';
import axios from 'utils/AxiosHelper';

const Productos = (props) => {

    //state
    const [currentCategory, setCurrentCategory] = useState('');
    const [showProductsList, setShowProducstList] = useState(false);
    const [categories, setCategories] = useState([]);
	const [bannerData, setBannerData] = useState({});

    //references
    const productsListRef = useRef(null);
    
    useEffect(() => {
        getCategories();
    }, [])

    const onSeeClickHandler = (category) => {
        window.scrollTo(0, 50000)
        setCurrentCategory(category);
        setShowProducstList(true);
    }

    const getCategories = async() => {
        await axios.get(`auth/product/productCategory`)
        .then(response => {
            if(response.status === 200){
                setCategories(response.data.data);
            }
        })
		await axios.get(`auth/general/banner`)
		.then(response => {
			setBannerData(response.data.data[1]);
		})
    }

    return (
		<>
			<Header {...props} />
			<div
				className="d-flex justify-content-center align-items-center"
				style={Object.assign( {}, style.image, {backgroundImage: `url(${axios.baseURLIMG}${bannerData.image})`} )}
			>
				<p style={{color: "white",fontSize: "4vh",textAlign: "center",fontWeight: "bold"}} className="corsoBanner" >
					{" "}{bannerData.title}{" "}
				</p>
			</div>
			<Container
				fluid
				className="d-flex justify-content-center align-items-center flex-column"
				style={{ padding: 0, margin: 0 }}
            >
                <Col xs="12" md="6" style={{ height: 200, padding:0, marginTop: 15, marginBottom: 20 }}>
                    <Row
                        className="justify-content-center align-items-center"
                        style={{
                            backgroundColor: "#426fb8",
                            height: "100%",
                            margin: 0
                        }}>
                        <Col className="d-flex justify-content-center align-items-center flex-column">
                            <h1 className="corsoTitle" style={{margin: 0, color: 'white', marginBottom: 10}} > MAQUILAMOS </h1>
                            <h1 className="corsoTitle" style={{margin: 0, color: 'white', fontWeight: '100'}} > PARA TI... </h1>
                        </Col>
                    </Row>
                    <div style={style.triangleRight}></div>
                    <div style={style.triangleLeft}></div>
                </Col>

                <Row className="justify-content-center align-items-center" style={{width: "100vw"}} >
                    {
                        categories.map((category, key) => {
                            return (
                                <Col
                                    xs="12"
                                    md="3"
                                    className="d-flex justify-content-center align-items-center"
                                    style={{
                                        backgroundImage: `url(${axios.baseURLIMG}${category.image})`,
                                        height: "30vh",
                                        marginBottom: 10,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        borderRadius: '1rem'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: 40,
                                            backgroundColor: '#00000026',
                                            width: '60%',
                                            height: 80,
                                            position: "absolute",
                                            top: 10,
                                        }}
                                    >
                                        <h2
                                            className="corsoTitle"
                                            style={{
                                                color: "black",
                                                marginBottom: 0,
                                                textAlign: "center",
                                                fontWeight: "500",
                                                textShadow:
                                                    "-1px 0 8px white, 0 1px white, 1px 0 white, 0 -1px white",
                                            }}
                                        >
                                            {" "}
                                            {category.name}{" "}
                                        </h2>
                                    </div>
                                    <Button
                                        outline
                                        color="secondary"
                                        style={{
                                            position: "absolute",
                                            bottom: 10,
                                            alignSelf: "center",
                                            backgroundColor: '#426fb8 !important'
                                        }}
                                        onClick={async () => {
                                            await setShowProducstList(false);
                                            onSeeClickHandler(category);
                                        }}
                                    >
                                        VER MÁS
                                    </Button>
                                </Col>
                            )
                        })
                    }
                </Row>

                {showProductsList && <ProducstList ref={productsListRef} {...props} category={currentCategory} />}
                
                <Row className="justify-content-center align-items-center" style={{margin: 0}}>
                    <h1 className="corsoTitle" style={{ color: "#426fb8", margin: 0, textShadow: "-1px 0px 2px black, 0 1px black, 1px 0 black, 0 -1px black", textAlign: "center" }} >
                        ¿NO ENCUENTRAS LO QUE BUSCABAS?
                    </h1>
                </Row>

                <Row style={{marginTop: 15}}>
                    <Col xs={12} md={6} >
                    </Col>
                    <Col xs={12} md={6} >
                        <Row
                            className="justify-content-center align-items-center"
                            style={{
                                backgroundColor: "#426fb8",
                                height: 200,
                                marginBottom: 20
                            }}>
                            <Col className="d-flex justify-content-center align-items-center flex-column">
                                <h1 className="d-none d-md-flex corsoTitle" style={{ margin: 10, padding: 20, color: 'white',textAlign: 'right' }} > 
                                    NOS ADECUAMOS A TUS CONFIGURACIONES DE INGREDIENTES O FICHA TECNICA
                                </h1>
                                <h1 className="d-md-none d-flex corsoTitle" style={{ margin: 10, padding: 20, color: 'white',textAlign: 'right', fontSize: 23 }} > 
                                    NOS ADECUAMOS A TUS CONFIGURACIONES DE INGREDIENTES O FICHA TECNICA
                                </h1>

                            </Col>
                            <div style={style.triangleLeft}></div>
                        </Row>
                    </Col>
                </Row>

                {/* <button onClick={() => {props.history.push("/productos/5")}} >Send</button> */}
                
			</Container>
			<Footer {...props} />
		</>
	);
}

const style = {
	image: {
		backgroundImage:
			"url(" + require("assets/img/productos/banner.png") + ")",
		height: "50vh",
		minWidth: "100%",
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
	triangleRight: {
		position: "absolute",
		top: 0,
		bottom: 0,
		right: 0,
		borderColor: "transparent #f5f6fa",
		borderStyle: "solid",
		borderWidth: "220px 100px 0px 0px",
	},
	triangleLeft: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		borderColor: "transparent #f5f6fa",
		borderStyle: "solid",
		borderWidth: "0px 0px 220px 100px",
	},
};

export default Productos;
