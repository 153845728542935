import React, { useState, useEffect } from "react";
import {
	Row,
    Container,
	Col,
	Button
} from "reactstrap";

import axios from 'utils/AxiosHelper';

import { MdThumbUp } from "react-icons/md";

import { BsListCheck } from 'react-icons/bs';
import { FaBoxes } from 'react-icons/fa';
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import 'react-responsive-carousel/lib/styles/carousel.css';

var Carousel = require('react-responsive-carousel').Carousel;

const carouselItems = [
	{
		src: require("assets/img/inicio/slide1.jpg"),
		altText: "Slide 1",
		caption: "COMERCIALIZACIÓN Y DISTRIBUCIÓN DE UNA AMPLIA GAMA DE PRODUCTOS",
	},
	{
		src: require("assets/img/inicio/slide2.jpg"),
		altText: "Slide 2",
		caption: "CONOCE LOS BENEFICIOS QUE TENEMOS PARA TI PARA IMPULSAR TU NEGOCIO",
	},
	{
		src: require("assets/img/inicio/slide3.jpg"),
		altText: "Slide 3",
		caption: "¿BUSCAS ALGO ESPECIAL? COTIZALO CON NOSOTROS",
	},
];

const cardsItems = [
	{
		img: require("assets/img/inicio/truck.svg")
	},
	{
		img: require("assets/img/inicio/chat.svg")
	},
	{
		img: require("assets/img/inicio/list-check.svg")
	},
];

const productos = [
	{
		img: require("assets/img/inicio/barras.jpg"),
		title: "Barras de cereal"
	},
	{
		img: require("assets/img/inicio/cookies.png"),
		title: "Galletas"
	},
	{
		img: require("assets/img/inicio/despensa.jpeg"),
		title: "Abarrotes"
	},
	{
		img: require("assets/img/inicio/trigo.jpg"),
		title: "Trigo inflado"
	},
];

const servicios = [
	{
		img: (<BsListCheck
			className="align-self-center"
			size={60}
			color="black"
			style={{ textAlign: "center" }}/>)
	},
	{
		img: (<FaBoxes
			className="align-self-center"
			size={60}
			color="black"
			style={{ textAlign: "center" }}/>)
	}
]

const Inicio = (props) => {

	useEffect(() => {
		getData();
	}, [])

	//state
	const [activeIndex, setActiveIndex] = useState(0);
	const [animating, setAnimating] = useState(false);
	const [customers, setCustomers] = useState([]);
	const [services, setServices] = useState([]);
	const [summaryCards, setSummaryCards] = useState([]);
	const [infoBanner, setInfoBanner] = useState({});
	const [carousel, setCarousel] = useState([]);
	const [categories, setCategories] = useState([]);
	const [loadedCategories, setLoadedCategories] = useState(false);

	const getData = async () => {
		//GET Customer images
		await axios.get('auth/home/customerImage')
		.then(response => {
			if (response.status === 200) {
				setCustomers(response.data.data);
			}
		});
		await axios.get("auth/home/services")
		.then(response => {
			if (response.status === 200) {
				setServices(response.data.data);
			}
		});
		await axios.get("auth/home/summaryCards")
		.then(response => {
			if (response.status === 200) {
				setSummaryCards(response.data.data);
			}
		});
		await axios.get("auth/home/informationBanner")
		.then(response => {
			if (response.status === 200) {
				setInfoBanner(response.data);
			}
		});
		await axios.get('auth/home/slider')
		.then(response => {
			if (response.status === 200) {
				setCarousel(response.data.data);
			}
		});
		
		await axios.get(`auth/product/productCategory`)
		.then(response => {
				if(response.status === 200){
					setCategories(response.data.data);
					setLoadedCategories(true);
				}
		})
	}

    return (
		<>
			<Header {...props} />
			<Carousel  showStatus={false} showThumbs={false} showArrows={true} infiniteLoop autoPlay={false} >
			{
				carousel.map((item, key) => {
					if (item.url !== null) {
						return (
							<iframe
								width="100%"
								height="100%"
								src={`https://www.youtube.com/embed/${item.url.split('?v=')[1]}`}
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							>	
							</iframe>
						);
					}
					else {
						return (
							<div key={key} className="d-flex justify-content-center align-items-center"
								style={{
									backgroundImage: `url(${axios.baseURLIMG}${item.image})`,
									height: "50vh",
									minWidth: "100%",
									backgroundSize: "cover",
									backgroundPosition: "center",
								}}
							>
								<p className="corsoBanner homeTitle" style={styles.carouselText}> {item.title} </p>
							</div>
						);
					}
				})
				}
			</Carousel>
			<Container fluid>
				<Row
					style={{
						minHeight: "20vh",
						marginBottom: 40,
						marginTop: 5,
					}}
				>
					<Col
						xs="12"
						md="6"
						className="d-flex justify-content-center align-items-start flex-column"
						style={{ paddingLeft: "4%", paddingRight: "4%" }}
					>
						<h2 className="corsoTitle " style={{textShadow: "-1px 0 8px white, 0 1px white, 1px 0 white, 0 -1px white"}} > {infoBanner.title} </h2>
						<p style={{ marginRight: 70, fontSize: "2vh" }}>
							{infoBanner.description}
						</p>
					</Col>
					<Col xs="12" md="6">
						<Row>
							<Col
								xs="1"
								style={{
									borderRight: "60px solid #426fb8",
									borderTop: "12vh solid transparent",
									borderBottom: "12vh solid transparent",
								}}
							></Col>
							<Col
								className="d-flex typography-line justify-content-center align-carouselItems-center"
								style={{
									backgroundColor: "#426fb8",
									flexDirection: "column",
								}}
							>
								<h1
									style={{
										textAlign: "center",
										color: "white",
									}}
									className="corsoTitle"
								>
									{" "}
									Calidad y confianza
								</h1>
								<MdThumbUp
									className="align-self-center"
									size={60}
									color="white"
									style={{ textAlign: "center" }}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="justify-content-around">
					{summaryCards.map((item, key) => {
						return (
							<Col
								xs="10"
								md="3"
								key={key}
								style={styles.card}
								className=""
							>
								<Row
									xs="3"
									className="justify-content-center align-items-center"
									style={{
										paddingTop: 10,
										paddingBottom: 10,
									}}
								>
									<img
										alt=""
										src={cardsItems[key].img}
										style={{ maxHeight: 80 }}
									/>
								</Row>
								<Row
									xs="3"
									className="justify-content-center align-items-center"
									style={{
										paddingTop: 10,
										paddingBottom: 10,
									}}
								>
									<h2
										className="corsoTitle"
										style={{ color: "white", textAlign: "center" }}
									>
										{item.title}
									</h2>
								</Row>
								<Row
									xs="9"
									style={{
										paddingLeft: 15,
										paddingRight: 15,
									}}
								>
									<p style={{ color: "white", fontWeight: '500' }}>{item.description}</p>
								</Row>
							</Col>
						);
					})}
				</Row>
				<Row className="justify-content-center align-items-center" style={{backgroundColor: "#2B2B2B", borderRadius: 50, height: 150, margin: 10}}>
					<h1 className="corsoTitle " style={{margin: 0, color: 'white'}} >PRODUCTOS</h1>
				</Row>
				<Row className="justify-content-around">
					{loadedCategories && categories.map((category, key) => {
						return (
							<Col
								key={key}
								xs="12"
								md="5"
								className="d-flex justify-content-center align-items-center"
								style={{
									backgroundImage: `url(${axios.baseURLIMG}${category.image})`,
									height: "50vh",
									minWidth: "45%",
									marginBottom: 10,
									backgroundSize: "cover",
									backgroundPosition: "center",
								}}
							>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: 40,
										backgroundColor: '#00000026',
										width: '60%',
										height: 80,
										position: "absolute",
										top: 10,
									}}
								>
									<h2
										className="corsoTitle"
										style={{
											color: "black",
											marginBottom: 0,
											textAlign: "center",
											fontWeight: "500",
											textShadow:
												"-1px 0 8px white, 0 1px white, 1px 0 white, 0 -1px white",
										}}
									>
										{" "}
										{category.name}{" "}
									</h2>
								</div>
								<Button
									outline
									color="secondary"
									style={{
										position: "absolute",
										bottom: 10,
										alignSelf: "center",
									}}
									onClick={(producto) => {}}
								>
									VER
								</Button>
							</Col>
						);
					})}
				</Row>
				<Row className="justify-content-center align-items-center" style={{backgroundColor: "#2B2B2B", borderRadius: 50, height: 150, margin: 10}}>
					<h1 className="corsoTitle " style={{margin: 0, color: 'white'}} >SERVICIOS</h1>
				</Row>
				<Row className="justify-content-around">
					{services.map((item, key) => {
						return (
							<Col
								key={key}
								xs="10"
								md="3"
								style={styles.card}
								className=""
							>
								<Row
									xs="3"
									className="justify-content-center align-items-center"
									style={{
										paddingTop: 10,
										paddingBottom: 10,
									}}
								>
									{servicios[key].img}
								</Row>
								<Row
									xs="3"
									className="justify-content-center align-items-center"
									style={{
										paddingTop: 10,
										paddingBottom: 10,
									}}
								>
									<h2 className="corsoTitle" style={{ color: "white", textAlign: "center" }} >
										{item.title}
									</h2>
								</Row>
								<Row xs="9" style={{ paddingLeft: 15, paddingRight: 15 }} >
									<p style={{ color: "white", fontWeight: '500' }}> {item.description} </p>
								</Row>
							</Col>
						);
					})}
				</Row>
				<Row className="justify-content-around">
					<h1 className="corsoTitle" style={{ padding: 10, margin: 0,textShadow: "-1px 0 8px white, 0 1px white, 1px 0 white, 0 -1px white", }}>
						NUESTROS CLIENTES NOS RESPALDAN
					</h1>
				</Row>
				<Row
					className="justify-content-around"
					style={{ marginBottom: 20 }}
				>
					{
						customers.map((customer, key) => {
							return (
								<img
									key={key}
									src={`${axios.baseURLIMG}${customer.image}`}
									style={{ maxHeight: 150, marginTop: 10 }}
									alt={customer.name}
								/>
							)
						})
					}
				</Row>
			</Container>
			<Footer {...props} />
		</>
	);
};

const styles = {
	carouselText: {
		color: "white",
		fontSize: "4vh",
		textAlign: "right",
		position: 'absolute',
		right: 10,
		textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
	},
	card: {
		backgroundColor: "#426fb8",
		borderRadius: 20,
		height: "350px",
		marginBottom: 20,
		justifyContent: "space-arouond",
		alignItems: "center",
	},
};

export default Inicio;
