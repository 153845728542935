/**
 * @fileoverview Auth reducer
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 30/10/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * 
 */

import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from '../actions';

export default (
  state = {
    isAuthenticated: false
  },
  action,
) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false
      };
    default:
      return state;
  }
};
