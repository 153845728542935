/**
 * @fileoverview Reducers
 * Exportación del catalogo de redicers 
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 30/10/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * 
 */

// NPM Packages Imports
import { combineReducers } from 'redux';

// Local Imports
import auth from './auth';

const createRootReducer = combineReducers({
  auth
});

export default createRootReducer;
