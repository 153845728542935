/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Row,
	Col,
	Button,
	FormGroup,
	Form,
	Input
} from "reactstrap";
import swal from 'sweetalert';

import axios from 'utils/AxiosHelper';

class Nosotros extends React.Component {
	constructor(props) {
		super(props);
        this.state = {
            inicios: "",
            iniciosmsg: "",
            mision: "",
            misionmsg: "",
            vision: "",
            visionmsg: ""
		};
		this.getData();
    }
    
    onChageData = e => {
        this.setState({ [e.target.name]: e.target.value });
	}

	getData = () => {
		axios.get("auth/aboutUs/presentationCards")
			.then(response => {
				var data = response.data.data;
				this.setState({
					inicios: data[0].title,
					iniciosmsg: data[0].description,
					misionmsg: data[1].description,
					visionmsg: data[2].description
				})
			});
	}
	
	onSaveAboutUs = (id) => {
		const data = {
			title: "",
			description: "",
			_method: "put"
		}
		switch (id) {
			case 1:
				data.title = this.state.inicios;
				data.description = this.state.iniciosmsg;
				break;
			case 2:
				data.title = "Misión";
				data.description = this.state.misionmsg;
				break;
			case 3:
				data.title = "Visión";
				data.description = this.state.visionmsg;
				break;
			default:
				break;
		}
		axios.post(`auth/aboutUs/presentationCards/${id}`, data)
			.then(response => {
				if (response.status === 200) {
					this.getData();
					swal("Correcto", "Actualizado correctamente", "success");
				}
		});
	}

	render() {
		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							{/* Tarjeta para editar las tarjetas del Menu de inicio */}
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">Nosotros</CardTitle>
								</CardHeader>
								<CardBody>
									<Form>
                                        <Col>
											<CardTitle tag="h4">Cinta informativa</CardTitle>
											<FormGroup>
												<label>Titulo</label>
												<Input
													name="inicios" value={this.state.inicios}
													onChange={(e) => { this.onChageData(e); }}
													placeholder="Titulo" type="text"
												/>
											</FormGroup>
											<FormGroup>
												<label>descripcion</label>
												<Input
                                                    name="iniciosmsg" value={this.state.iniciosmsg}
													onChange={(e) => { this.onChageData(e); }}
													placeholder="..." cols="80" rows="4" type="textarea"
												/>
											</FormGroup>
											<Button
												onClick={() => this.onSaveAboutUs(1)} className="btn-fill" color="primary"
											>
												Actualizar
											</Button>
										</Col>
                                        <Col>
                                            <CardTitle tag="h4">Misión</CardTitle>
											<FormGroup>
												<label>descripcion</label>
												<Input
													onChange={(e) => { this.onChageData(e); }}
													name="misionmsg" value={this.state.misionmsg} placeholder="..." cols="80" rows="4" type="textarea"
												/>
											</FormGroup>
											<Button onClick={() => this.onSaveAboutUs(2)} className="btn-fill" color="primary" >
											Actualizar
											</Button>
										</Col>
                                        <Col>
                                            <CardTitle tag="h4">Visión</CardTitle>
											<FormGroup>
												<label>descripcion</label>
												<Input
                                                    name="visionmsg" value={this.state.visionmsg}
													onChange={(e) => { this.onChageData(e); }}
													placeholder="..." cols="80" rows="4" type="textarea"
												/>
											</FormGroup>
										</Col>
									</Form>
								</CardBody>
								<CardFooter>
									<Button
										onClick={() => this.onSaveAboutUs(3)} className="btn-fill" color="primary"
									>
										Actualizar
									</Button>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default Nosotros;
