import React, {useState, useEffect} from 'react';
// reactstrap components
// reactstrap components
import {
	Button,
	FormGroup,
	Input,
} from "reactstrap";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Files from "react-files";
import swal from 'sweetalert';

import axios from 'utils/AxiosHelper';

const ProductForm = (props) => {

    //state
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [ingredients, setIngredients] = useState('');
    const [top, setTop] = useState(0);
    const [product_category_id, setProduct_category_id] = useState(1);
    const [image, setImage] = useState({preview: {url: ''}});
    const [update, setUpdate] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if(props.category.hasOwnProperty('image')){
            setImage({preview: {url: `${axios.baseURLIMG}${props.category.image}`}});
            setTitle(props.category.title);
            setProduct_category_id(props.category.product_category_id);
            setIngredients(props.category.ingredients);
            setDescription(props.category.description);
            setUpdate(true);
        }
        getCategories();
    }, [])

    const getCategories = () => {
        axios.get('auth/product/productCategory')
        .then(response => {
            if(response.status === 200){
                setCategories(response.data.data);
            }
        })
    }

    const onSaveClickHandler = () => {
        if( (title !== '') && (image !== {}) ){
            if(update){
                axios.post(`auth/product/${props.category.id}`, {title,
                description,
                ingredients,
                top,
                product_category_id,
                image}, 'put')
                .then(response => {
                    if(response.status === 200){
                        swal("Correcto", "Categoria agregada correctamente", "success");
                    }
                })
                .catch(error => {
                    swal("Error", "Ocurrio un error al agregar la categoria", "error");
                })
            }
            else{
                axios.post(`auth/product`, {title,
                description,
                ingredients,
                top,
                product_category_id,
                image})
                .then(response => {
                    if(response.status === 200){
                        swal("Correcto", "Categoria agregada correctamente", "success");
                    }
                })
                .catch(error => {
                    swal("Error", "Ocurrio un error al agregar la categoria", "error");
                })
            }
        }
    }
    
    return(
        <>
        <div  style={{display: 'flex', flexDirection: 'column'}}>
            <FormGroup>
                <label>Imagen</label>
                <Files
                    className="files-dropzone"
                    onChange={(file) => {
                        setImage(file[0]);
                    }}
                    onError={(err) => {} }
                    accepts={["image/*"]} multiple={false} maxFileSize={10000000} minFileSize={0}
                >
                    <p
                        style={{ borderStyle: "dashed", color: "black", borderWidth: 0.5, padding: 15 }}
                        className={"dashed"}
                    >
                        Arrastre imagenes aquí o presione para agregar
                    </p>
                </Files>
            </FormGroup>
            <label>previsualización</label>
            <FormGroup>
                <img alt="" src={image.preview.url} style={{ maxHeight: 200 }} />
            </FormGroup>
            <FormGroup>
                <label>Titulo</label>
                <Input
                    style={{color: 'black'}}
                    name="title"
                    value={title}
                    onChange={(e) => {setTitle(e.target.value)}}
                    placeholder="..."
                />
            </FormGroup>
            <FormControl>
                <InputLabel id="top-label">Producto Top</InputLabel>
                <Select
                    labelId="top-label"
                    value={top}
                    onChange={(e) => {setTop(e.target.value)}}
                >
                    <MenuItem value={0}>No</MenuItem>
                    <MenuItem value={1}>Si</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel id="category-label">Categoria</InputLabel>
                <Select
                    labelId="category-label"
                    value={product_category_id}
                    onChange={(e) => {setProduct_category_id(e.target.value)}}
                >
                    {
                        categories.map((item, key) => {
                            return(
                                <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            <FormGroup>
                <label>Descripción</label>
                <Input
                    style={{color: 'black'}}
                    value={description}
                    onChange={(e) => {setDescription(e.target.value)}}
                    placeholder="..."
                    cols="80"
                    rows="4"
                    type="textarea"
                />
            </FormGroup>
            <FormGroup>
                <label>Ingredientes</label>
                <Input
                    style={{color: 'black'}}
                    value={ingredients}
                    onChange={(e) => {setIngredients(e.target.value)}}
                    placeholder="..."
                    cols="80"
                    rows="4"
                    type="textarea"
                />
            </FormGroup>
            <Button onClick={onSaveClickHandler} className="btn-fill" color="primary" >
                { update? 'Actualizar':'Agregar'}
            </Button>
        </div>
        </>
    )
}

export default ProductForm;