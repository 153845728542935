import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import axios from 'utils/AxiosHelper';
import {useDispatch} from 'react-redux';
import { loginUser } from 'redux/actions';

import logo from "assets/img/LOGO_CORSO_HORIZONTAL.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1e1e2f',
    height: '100vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  input: {
    backgroundColor: '#1e1e2f',
    color: 'white !important'
  }
}));

const Login = (props) => {

  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('El email debe ser un email valido').max(255).required('El email es requerido'),
              password: Yup.string().max(255).required('La contraseña es requerida')
            })}
            onSubmit={(values, { setSubmitting }) => {
              axios.post('auth/login', values)
              .then(response => {
                if (response.status === 200) {
                  // navigate('/app/inicio', { replace: true });
                  dispatch(loginUser());
                  props.history.push('/admin');
                }
              })
              .catch(error => { });
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <div className="logo-img">
                      <img style={{maxWidth: '200px'}} src={logo} alt="react-logo" />
                  </div>
                  <Typography
                    color="white"
                    variant="h2"
                  >
                    Iniciar sesión
                  </Typography>
                </Box>
                <TextField
                  InputProps={{
                    className: clsx(classes.input)
                  }}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  InputProps={{
                    className: clsx(classes.input)
                  }}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Contraseña"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Iniciar sesión
                  </Button>
                </Box>
                {/* <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography> */}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default Login;
