// Components
import Inicio from "screens/Inicio/Inicio";
import Nosotros from "screens/Nosotros/Nosotros";
import Productos from "screens/Productos/Productos";
import Contacto from "screens/Contacto/Contacto";
import Innovacion from "screens/Innovacion/Innovacion";
import Cotiza from "screens/Cotiza/Cotiza";

var routes = [
    {
        path: "/inicio",
        name: "Inicio",
        icon: "tim-icons icon-align-center",
        component: Inicio,
        layout: "/",
    },
    {
        path: "/nosotros",
        name: "Nosotros",
        icon: "tim-icons icon-align-center",
        component: Nosotros,
        layout: "/",
    },
    {
        path: "/productos",
        name: "Productos",
        icon: "tim-icons icon-align-center",
        component: Productos,
        layout: "/",
    },
    {
        path: "/contacto",
        name: "Contacto",
        icon: "tim-icons icon-align-center",
        component: Contacto,
        layout: "/",
    },
    {
        path: "/innovacion",
        name: "Innovación",
        icon: "tim-icons icon-align-center",
        component: Innovacion,
        layout: "/",
    },
    {
        path: "/cotiza",
        name: "¡Cotiza Ya!",
        icon: "tim-icons icon-align-center",
        component: Cotiza,
        layout: "/",
    },
];
export default routes;
