import React from 'react';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect} from "react-router-dom";
import { Navigate } from 'react-router-dom';
import { AdminLayout, HomeLayout } from "registerScreens";
import Login from 'screens/Login/Login';
import {useSelector} from 'react-redux';

import "assets/scss/black-dashboard-react.scss";
import "assets/css/nucleo-icons.css";
import "styles/globalStyles.css";

const hist = createBrowserHistory();

const App = () => {

    //redux
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

    return(
        <Router history={hist}>
            <Switch>
                <Route path="/admin" render={(props) => isAuthenticated? <AdminLayout {...props} /> : <Redirect from="*" to="/login" />} />
                <Route exact path="/login" render={(props) => <Login {...props}/>} />
                <Route path="/" render={(props) => <HomeLayout {...props} />} />
                <Redirect from="*" to="/" />
            </Switch>
        </Router>
    )
}

export default App;