import React, {useState, useEffect} from 'react';
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Row,
    Col,
    Button
} from "reactstrap";

import axios from 'utils/AxiosHelper';
import swal from 'sweetalert';

import Table from 'components/CutomTable/CustomTable';
import CustomModal from 'components/CustomModal';
import CategorieForm from './CustomersForm';

const cols = [
    {accessor: "id", header: "ID"},
    {accessor: "name", header: "Nombre"}
];

const Customers = (props) => {

    //state
    const [categories, setCategories] = useState([]);
    const [currenrCategory, setCurrenrCategory] = useState({});
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        axios.get('auth/home/customerImage')
        .then(response => {
            if(response.status === 200){
                setCategories(response.data.data);
            }
        })
    }

    const onShowCategory = (item) => {
        //TODO:
    }

    const onDelete = async(item) => {
        await swal({
            title: "¿Estás seguro?",
            text: "Una vez eliminado no se podra recuperar el registro.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                axios.post(`auth/home/customerImage/${item.id}`, {name: item.name}, 'delete')
                .then(response => {
                    if(response.status === 200){
                        swal("Registro eliminado correctamente", {icon: "success"});
                    }
                })
            }
        });
        getData();
    }

    const onEdit = (item) => {
        setCurrenrCategory(item);
        setShowForm(true);
    }

    const onAddNewCategory = () => {
        setCurrenrCategory({});
        setShowForm(true);
    }

    const closeForm = () => {
        setShowForm(false);
        getData();
    }
    
    return(
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        {/* Tarjeta para editar las tarjetas del Menu de inicio */}
                        <Card>
                            <CardHeader className="mb-5">
                                <CardTitle tag="h3">Clientes</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table 
                                    cols={cols}
                                    rows={categories}
                                    actions
                                    showAction={false}
                                    onShow={item => onShowCategory(item)}
                                    onDelete={item => onDelete(item)}
                                    onEdit={item => onEdit(item)}
                                />
                                {/* <Form>
                                    <Col>
                                        <CardTitle tag="h4">Cinta informativa</CardTitle>
                                        <FormGroup>
                                            <label>Titulo</label>
                                            <Input
                                                name="inicios" value={'inicios'}
                                                onChange={(e) => { this.onChageData(e); }}
                                                placeholder="Titulo" type="text"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>descripcion</label>
                                            <Input
                                                name="iniciosmsg" value={'iniciosmsg'}
                                                onChange={(e) => { this.onChageData(e); }}
                                                placeholder="..." cols="80" rows="4" type="textarea"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Form> */}
                            </CardBody>
                            <CardFooter>
                                <Button
                                    onClick={onAddNewCategory} className="btn-fill" color="primary"
                                >
                                    Añadir nuevo cliente
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
            <CustomModal
                open={showForm}
                handleClose={() => setShowForm(false)}
            >
                <CategorieForm category={currenrCategory} closeForm={closeForm} />
            </CustomModal>
        </>
    )
}

export default Customers;